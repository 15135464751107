import { ArrowForward, Close, Info, ListAlt, Search } from "@material-ui/icons";
import {
  CalendarToday,
  Cancel,
  Clear,
  Delete,
  Download,
  DownloadOutlined,
  ExpandLess,
  FilterAltOutlined,
  FilterList,
} from "@mui/icons-material";
import {
  alpha,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { DateRange } from "react-date-range";
import styled from "styled-components";
import {
  backendApiCall,
  convertToIndianNumberFormat,
  formatDate,
  formatDateDynamic,
  formatServerValue,
  getAuthToken,
  getAxiosError,
  getComparator,
  getNumberFromString,
  getRandomDarkColor,
  getRandomInt,
  getStoreId,
  getTenantId,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDateInRange,
  isObjWithValues,
  jweroColorPalette,
  numDifferentiation,
  primaryLabel,
  primaryLabelLarge,
  reduceLargeText,
  secondaryLabel,
  sleep,
  stableSort,
  validateNumber,
  WEBSOCKET_NGROK_URL,
  websocketApiCall,
} from "../../helper";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/system";
import { updateDigiPlanInRedux } from "../../redux/actions/productActions";
import axios from "axios";
import { GoldCoin } from "../../Svgs";
import { currencySymbols } from "../pricing/Gold";
import { isValidDate } from "../../utils/dateFunctions";
import {
  allPaymentStatus,
  getStatusBackgroundColor,
  statusFontColor,
} from "../orders/Orders";
import { DigiMissedPayments } from "./Payments";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { DashboardTotalCard } from "../dashboards/Default";
import * as XLSX from "xlsx";
import { utils } from "xlsx";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // core Swiper
import {
  checkUserAllPaymentsModeInDigigold,
  getFirstPlanDate,
  getPlanJoiningDate,
} from "../../utils/digigold";
import CustomChip from "../components/CustomChip";
import { backendEndpoints } from "../chat_new/apiFunctions";
import { downloadPDF, openPDF } from "../../utils/pdf";
import HorizontalStack from "../../helper/utility/HorizontalStack";
import {
  ErrorLabelExtraSmall,
  PrimaryLabelSmall,
} from "../../helper/utility/Labels";
import { LoadingButton } from "@mui/lab";

// export const digigoldGlobalColors = [
//   "#5F8D4E",
//   "#7D8F69",
//   "#A9AF7E",
//   "#A4BE7B",
//   "#B6E2A1",
//   "#E5D9B6",
// ];
export const digigoldGlobalColors = [
  "#012687",
  "#0012b9",
  "#616ada",
  "#f6b11c",
  // "#67C587",
  // "#A9DEBA",
  // "#C9EAD4",
  // "#EAF6ED",
  // "#B6E2A1",
  // "#E5D9B6",
  // ...jweroColorPalette
];

function Dashboard({
  digiCustomers,
  digiPlans,
  dashboardTotals,
  currency,
  orders,
  setUserView,
  todayGoldRate,
  topGraphs,
  digiCancelledPlans,
  setPlanView,
  setSelectedTab,
  setupCustomers,
}) {
  useEffect(() => {
    if (!isArrayWithValues(digiPlans)) return;
  }, [digiPlans]);

  // const updateLedgerIn  = (ledger, customer_id) => {
  //   if(isArrayWithValue3x)
  // }

  return (
    <Box>
      <TopCards
        topGraphs={topGraphs}
        dashboardTotals={dashboardTotals}
        setSelectedTab={setSelectedTab}
        currency={currency}
      />
      <PlansContributions customers={digiCustomers} plans={digiPlans} />
      <PlansCards plans={digiPlans} />
      <PlansTable
        customers={digiCustomers}
        plans={digiPlans}
        currency={currency}
        setPlanView={setPlanView}
      />
      <CustomersTable
        customers={digiCustomers}
        plans={digiPlans}
        currency={currency}
        setUserView={setUserView}
        digiCancelledPlans={digiCancelledPlans}
      />
      <Grid container spacing={3} sx={{ pb: 5 }}>
        <Grid item xs={12} md={5.5}>
          <CustomerEnrolledGraph topUsersPlans={digiPlans} />
        </Grid>
        <Grid item xs={12} md={6.5}>
          <CustomerRegisteredGraph users={digiCustomers} />
        </Grid>
      </Grid>
      <DigiOrdersTable
        orders={orders}
        customers={digiCustomers}
        setUserView={setUserView}
        plans={digiPlans}
        currency={currency}
      />
      <DigiMissedPayments
        orders={orders}
        currency={currency}
        customers={digiCustomers}
        digiPlans={digiPlans}
        todayGoldRate={todayGoldRate}
        setUserView={setUserView}
      />
    </Box>
  );
}

export default Dashboard;

export const TopCards = ({
  dashboardTotals,
  topGraphs,
  setSelectedTab,
  currency,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6} sm={6} md={4} lg={3}>
        <DashboardTotalCard
          title="Total Plans"
          value={dashboardTotals.totalPlans}
          values={[
            {
              title: "In amt",
              value: dashboardTotals?.totalAmountPlans || 0,
              // prefix: currencySymbols[currency],
            },
            {
              title: "In grams",
              value: dashboardTotals?.totalGramsPlans || 0,
              // suffix: "g",
            },
            {
              title: "In flexi",
              value: dashboardTotals?.totalFlexiPlans || 0,
              // suffix: "g",
            },
          ]}
          graphData={topGraphs?.plans}
          onClick={() => setSelectedTab(1)}
          // breakup={plansBreakup}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={3}>
        <DashboardTotalCard
          title="Expected Collections"
          value={
            numDifferentiation(
              dashboardTotals?.totalExpectedCollectionAmount
            ) || 0
          }
          values={[
            {
              title: "In amt",
              value:
                numDifferentiation(
                  dashboardTotals?.totalExpectedCollectionAmount
                ) || 0,
              prefix: currencySymbols[currency],
              tooltip: `${currencySymbols[currency] || ""} ${
                convertToIndianNumberFormat(
                  dashboardTotals?.totalExpectedCollectionAmount
                ) || ""
              }`,
            },
            {
              title: "In grams",
              value: dashboardTotals?.totalExpectedCollectionGrams || 0,
              suffix: "g",
            },
          ]}
          graphData={topGraphs?.expected}
          onClick={() => setSelectedTab(2)}
          // onClick={() => navigate("users")}
          // breakup={totalUsersBreakup}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={3}>
        <DashboardTotalCard
          title="Total Collections"
          value={
            numDifferentiation(dashboardTotals?.totalCollectionAmount) || 0
          }
          values={[
            {
              title: "In amt",
              value:
                numDifferentiation(dashboardTotals?.totalCollectionAmount) || 0,
              prefix: currencySymbols[currency],
              tooltip: `${currencySymbols[currency] || ""} ${
                convertToIndianNumberFormat(
                  dashboardTotals?.totalCollectionAmount
                ) || ""
              }`,
            },
            {
              title: "In grams",
              value: dashboardTotals?.totalCollectionGrams || 0,
              suffix: "g",
            },
          ]}
          onClick={() => setSelectedTab(2)}
          graphData={topGraphs?.received}
          // breakup={totalAmountBreakup}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={3}>
        <DashboardTotalCard
          title="Missed Payments"
          // value={`${0} g`}
          value={numDifferentiation(dashboardTotals?.totalMissedAmount)}
          values={[
            {
              title: "In amt",
              value:
                numDifferentiation(dashboardTotals?.totalMissedAmount) || 0,
              prefix: currencySymbols[currency],
              tooltip: `${currencySymbols[currency] || ""} ${
                convertToIndianNumberFormat(
                  dashboardTotals?.totalMissedAmount
                ) || ""
              }`,
              valueSx: { color: "error.main" },
            },
            {
              title: "In grams",
              value: dashboardTotals?.totalMissedGrams || 0,
              suffix: "g",
              valueSx: { color: "error.main" },
            },
          ]}
          onClick={() => setSelectedTab(3)}
          graphData={topGraphs?.missed}
          graphColors={["#d32f2f"]}
          // breakup={totalGramsBreakup}
        />
      </Grid>
    </Grid>
  );
};

const PlansCards = ({ customers, plans, currency }) => {
  let [rows, setRows] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (!isArrayWithValues(plans)) return;
    setRows(plans);
  }, [plans]);
  return (
    <Box sx={{ mt: 3, py: 3, maxWidth: "100%" }}>
      <SectionLabel sx={{ mb: 2 }}>Digi Gold Plans</SectionLabel>
      {/* <ScrollMenu
        scrollContainerClassName="scrollbar-hidden"
        options={{ ratio: 0.9 }}
      >
        {rows.map((i) => (
          <PlanCard info={i} showMore={showMore} currency={currency} />
        ))}
      </ScrollMenu> */}
      <Grid container spacing={2}>
        {rows.map((i) => {
          return (
            <Grid item xs={12} md={6} lg={4}>
              <PlanCard info={i} showMore={showMore} currency={currency} />
            </Grid>
          );
        })}
      </Grid>
      {/* <Swiper
        spaceBetween={30}
        slidesPerView={"auto"}
        initialSlide={0}
        autoplay={false}
        // width={"50vw"}
        style={{ maxWidth: "100%" }}
        // centeredSlides={true}
        // initialSlide={}

        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {rows.map((i) => (
          <SwiperSlide style={{ maxWidth: isIpad ? "100%" : "30%" }}>
            <PlanCard info={i} showMore={showMore} currency={currency} />
          </SwiperSlide>
        ))}
      </Swiper> */}
      {/* <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          onClick={() => setShowMore((state) => !state)}
          startIcon={
            <ExpandLess
              sx={{
                transform: !showMore ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 0.4s",
              }}
            />
          }
        >
          View {showMore ? "Less" : "More"}
        </Button>
      </Box> */}
    </Box>
  );
};

const PlanCard = ({ info, currency = "", showMore }) => {
  let [product, setProduct] = useState({});

  const [statusChanging, setStatusChanging] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  // const currency = useSelector(state => state.settings.productSettings)?.default_currency

  let { images, name } = info;

  useEffect(() => {
    let { meta_data, price } = info;
    const metaKeys = [
      "digi_plan_type",
      "gold_kt",
      "reward_type",
      "plan_total_grams",
      "digi_plan_duration",
      "customer_limit",
      "customer_limit_restriction",
      "terms_and_conditions",
      "payment_restriction",
      "payment_restriction_days",
      "grams_on_amount",
      "reward",
      "jeweller_contribution",
      "manual_price",
    ];
    let productObj = {
      status: info.status,
      amount: price,
    };
    if (isArrayWithValues(meta_data))
      for (let obj of meta_data)
        if (metaKeys.includes(obj.key)) productObj[obj.key] = obj.value;

    setProduct({ ...productObj, ...info });
  }, [info]);

  const onStatusChange = async (id, status) => {
    const website = await getWebsite();
    const token = await getToken();
    if (website && token) {
      setStatusChanging(true);
      try {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/products/${id}`,
          headers: { Authorization: `Basic ${token}` },
          method: "PUT",
          data: { status },
        });
        if (isObjWithValues(data)) dispatch(updateDigiPlanInRedux(data));
      } catch (error) {
        console.log(getAxiosError(error));
      }
      setStatusChanging(false);
    }
  };
  // return (
  //   <Card>
  //     <CardContent>Hello</CardContent>
  //   </Card>
  // );
  // console.log(product, "<<<<<<<<<<<< product");
  return (
    <Card
      sx={{
        borderRadius: "8px",
        // background:
        //   "linear-gradient(10deg, rgba(242,232,199,1) 0%, rgba(250,247,240,1) 100%)",
        // cursor: "pointer",
        height: "100%",
        position: "relative",
        // width: "350px",
        overflow: "hidden",
        // mb: 2,
      }}
      // className="shadow_card"
      // onClick={() => onCardClick(info)}
    >
      <CardContent sx={{ overflow: "hidden", flexWrap: "wrap" }}>
        {/* <IconButton sx={{ position: "absolute", top: 10, right: 10 }}>
          <Switch
            disabled={statusChanging}
            checked={product.status === "publish"}
            onChange={(e) =>
              onStatusChange(
                info.id,
                product.status === "publish" ? "draft" : "publish"
              )
            }
            style={{
              border: `1px solid ${
                product.status === "publish" ? backgroundColor : "#e8e8e8"
              }`,
              backgroundColor:
                product.status === "publish" ? backgroundColor : "#e8e8e8",
            }}
          />
        </IconButton> */}
        {isArrayWithValues(images) ? (
          <Box
            sx={{
              position: "relative",
              maxWidth: "40%",
              margin: "20px auto",
              // backgroundColor: "blue",
              borderRadius: "50%",
              overflow: "hidden",
              // boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
              // p: 4
            }}
          >
            <img
              src={images[0].src}
              style={{
                position: "relative",
                maxWidth: "100%",
                // margin: "0px auto",
                // backgroundColor: "blue",
                borderRadius: "50%",
                overflow: "hidden",
                // boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
                // p: 4
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              position: "relative",
              maxWidth: "40%",
              margin: "12px auto",
              padding: "5px",
              // backgroundColor: "blue",
              borderRadius: "50%",
              // boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
              // p: 4
            }}
          >
            <GoldCoin />
            <Box
              sx={{
                position: "absolute",
                top: product.digi_plan_type === "grams" ? "43%" : "49%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                alignItems: "center",
                opacity: 0.6,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Georgia, serif",
                  textShadow: "2px 2px #daaf17",
                  fontSize:
                    product.digi_plan_type === "grams" ? "70px" : "27px",
                  fontWeight: "500",
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                }}
              >
                {product.digi_plan_type === "grams"
                  ? product.plan_total_grams
                  : `${currency} ${product.amount}`}
              </Typography>
              <Typography
                variant="h5"
                textAlign={"center"}
                mt={0.5}
                sx={{
                  fontFamily: "Georgia, serif",
                  textShadow: "2px 2px #daaf17",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {product.digi_plan_type === "grams" ? "Grams" : `Per Month`}
              </Typography>
            </Box>
          </Box>
        )}
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            mt: 1.5,
            textAlign: "center",
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            // fontWeight: "600",
            mt: 0.5,
            textAlign: "center",
            color: "text.secondary",
          }}
        >
          {product.digi_plan_duration} months
        </Typography>
        <PlanCardColorBox sx={{ backgroundColor: "#fafafa" }}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography sx={{ textAlign: "center" }}>
              Total Expected Collection
            </Typography>

            <Typography
              sx={{ textAlign: "center", fontSize: "15px", fontWeight: "500" }}
            >
              {currencySymbols[currency]}{" "}
              {convertToIndianNumberFormat(
                validateNumber(product.totalExpected)
              ) || 0}
            </Typography>
          </Stack>
        </PlanCardColorBox>
        <PlanCardColorBox sx={{ backgroundColor: "#f1f2e6" }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <PlanCardLabel>Recevied Collection</PlanCardLabel>
              <PlanCardValue>
                {" "}
                {currencySymbols[currency]}{" "}
                {convertToIndianNumberFormat(product.totalPaid) || 0}
              </PlanCardValue>
            </Grid>
            <Grid item xs={4}>
              <Tooltip title="Missed payment excluded">
                <div>
                  <PlanCardLabel>Balance Collection</PlanCardLabel>
                  <PlanCardValue>
                    {" "}
                    {currencySymbols[currency]}{" "}
                    {convertToIndianNumberFormat(product.totalBalance) || 0}
                  </PlanCardValue>
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <PlanCardLabel>Missed Payments</PlanCardLabel>
              <PlanCardValue>
                {" "}
                {currencySymbols[currency]}
                {convertToIndianNumberFormat(product.totalMissed)}
              </PlanCardValue>
            </Grid>
          </Grid>
        </PlanCardColorBox>
        <PlanCardColorBox sx={{ backgroundColor: "#FAEDCD" }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <PlanCardLabel>Total Customer</PlanCardLabel>
              <PlanCardValue>{product.totalUsers || 0}</PlanCardValue>
            </Grid>
            <Grid item xs={4}>
              <PlanCardLabel>No. of Payments</PlanCardLabel>
              <PlanCardValue>
                {product.totalPayments || 0}/
                {product.totalPaymentsExpected || 0}
              </PlanCardValue>
            </Grid>
            <Grid item xs={4}>
              <PlanCardLabel>Gold Accumulated</PlanCardLabel>
              <PlanCardValue>
                {info.totalPaidInGrams
                  ? `${validateNumber(info.totalPaidInGrams)?.toFixed(2)} grams`
                  : ""}
              </PlanCardValue>
            </Grid>
          </Grid>
        </PlanCardColorBox>
        <>
          <PlanCardColorBox sx={{ backgroundColor: "#FFF1E5" }}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <PlanCardLabel>Days since plan launch</PlanCardLabel>
                <PlanCardValue>{product.totalDaysSincePlan || 0}</PlanCardValue>
              </Grid>
              <Grid item xs={4}>
                <PlanCardLabel>Avg Collection / day</PlanCardLabel>
                <PlanCardValue>
                  {currencySymbols[currency]}{" "}
                  {product.avaragePerDayCollection || 0}
                </PlanCardValue>
              </Grid>
              <Grid item xs={4}>
                <PlanCardLabel>Avg Collection / month</PlanCardLabel>
                <PlanCardValue>
                  {currencySymbols[currency]}{" "}
                  {product.avaragePerMonthCollection || 0}
                </PlanCardValue>
              </Grid>
            </Grid>
          </PlanCardColorBox>
          <PlanCardColorBox sx={{ backgroundColor: "#ebebeb" }}>
            <Typography sx={{ textAlign: "center" }}>
              Total Contribution
            </Typography>
            <Typography
              sx={{
                mt: 3,
                fontWeight: "500",
                fontSize: "17px",
                textAlign: "center",
              }}
            >
              {product.totalPlanContribution}%
            </Typography>
          </PlanCardColorBox>
        </>
        <Collapse in={showMore}></Collapse>
        {/* <Stack
          mt={1}
          direction="row"
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography variant="h6" sx={{ fontSize: "13px" }}>
            Duration:
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: "500", ml: 1 }}
          >
            {product.digi_plan_duration || "0"} Months
          </Typography>
        </Stack>

        <Typography
          sx={{
            fontSize: "12px",
            mt: 1.5,
            textAlign: "center",
            color: "text.secondary",
          }}
        >
          {formatDate(new Date(info.date_created), "dd:mm:yyyy hh:mm am/pm")}
        </Typography>
        <Stack direction="row" mt={2} justifyContent={"center"}>
          <ButtonGroup
            variant="text"
            size="small"
            aria-label="text button group"
          >
            <Button onClick={() => {}}>Edit</Button>
            <Button color="error">Delete</Button>
          </ButtonGroup>
        </Stack> */}
      </CardContent>
    </Card>
  );
};

const defaultPaymentsFilterState = {
  search: "",
  startDate: "",
  endDate: "",
  branches: [],
  plans: [],
};

export const DigiOrdersTable = ({
  orders,
  currency,
  customers,
  setUserView,
  plans,
  plan_id,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const theme = useTheme();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(defaultPaymentsFilterState);
  const [filterShow, setFilterShow] = useState(false);
  const [allPlans, setAllPlans] = useState([]);
  const [allLedgers, setAllLedger] = useState([]);
  const store_id = getStoreId();

  const reduxBranches = useSelector((state) => state.settings.branch);

  const [branches, setBranches] = useState([]);
  const [pdfLoading, setPdfDownloading] = useState(false);

  const [downloadAnchorEl, setDownloadAnchorEl] = React.useState();
  let store_details = useSelector((state) => state.user.store_details);

  const dispatch = useDispatch();

  const downloadOpen = Boolean(downloadAnchorEl);
  const handleDownloadClick = (event) => {
    setDownloadAnchorEl(event.currentTarget);
  };
  const handleDownloadClose = () => {
    setDownloadAnchorEl(null);
  };
  useEffect(() => {
    if (isObjWithValues(reduxBranches))
      setBranches(Object.values(reduxBranches));
  }, [reduxBranches]);

  useEffect(() => {
    if (!isArrayWithValues(plans)) return;
    setAllPlans(plans.map((i) => ({ label: i.name, value: i.id })));
  }, [plans]);

  useEffect(() => {
    onFilterPress();
  }, [filter]);

  const onCSVdownload = () => {
    try {
      if (!isArrayWithValues(rows)) return;
      let array = [];
      let Amount = 0;
      // console.log(rows, "<<<<< rows");
      // return;
      for (let order of rows) {
        let row = getDigigoldLedgersCSV({ order, currency, store_id, plan_id });
        Amount += validateNumber(row?.Amount);
        array.push(row);
      }
      array.push({});
      array.push({ Amount });
      exportAsExcel({
        data: array,
        fileName: `Customer Payments - ${formatDate(
          new Date(),
          "dd:mm:yyyy hh:mm am/pm"
        )}`,
        sheetName: "Payments",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const onPDFDownload = async () => {
    try {
      if (!isArrayWithValues(rows)) return;
      setPdfDownloading(true);
      let array = [];
      let Amount = 0,
        index = 0;
      let total_payments = 0,
        total_received = 0,
        total_online_payments = 0,
        total_offline_payments = 0;
      let customerIds = [],
        duration_range = "";

      if (filter?.startDate && filter?.endDate)
        duration_range = `${formatDate(
          new Date(filter?.startDate),
          "yyyy-mm-dd"
        )} - ${formatDate(new Date(filter?.endDate), "yyyy-mm-dd")}`;

      for (let order of rows) {
        let { totalPaid, totalExpected, plans, id, manual } = order;
        let row = getDigigoldLedgersPDF({
          order,
          currency,
          store_id,
          plan_id,
          index,
        });
        row.Remarks = " ";
        Amount += validateNumber(row?.Amount);
        array.push(row);
        total_payments += validateNumber(totalExpected);
        total_received += validateNumber(totalPaid);
        if (!customerIds?.includes(id)) customerIds.push(id);
        if (manual) total_offline_payments += 1;
        else total_online_payments += 1;
        index++;
      }
      array.push({});
      array.push({ Amount });

      let total_no_payments = total_online_payments + total_offline_payments;

      let table_aggregation = [];
      let filtersAggregation = {};

      if (isArrayWithValues(filter.branches))
        filtersAggregation["Branch"] = filter.branches?.join(", ");
      if (isArrayWithValues(filter.plans)) {
        filtersAggregation["Total Plans"] = filter.plans
          ?.map((i) => {
            let plan = allPlans.find((j) => `${j.value}` === `${i}`);
            if (plan?.label) return plan.label;
          })
          ?.filter(Boolean)
          ?.join(", ");
      }
      table_aggregation.push(filtersAggregation);
      table_aggregation.push({
        "Total Customers": customerIds?.length,
        "Advance Received": `${currencySymbols[currency]} ${
          convertToIndianNumberFormat(total_payments) || ""
        }`,
      });
      table_aggregation.push({
        "Total Payments": `${rows?.length}`,
        "Online Payments": `${total_online_payments} (${Math.round(
          (total_online_payments / total_no_payments) * 100
        ).toFixed(2)}%)`,
        "Offline Payments": `${total_offline_payments} (${Math.round(
          (total_offline_payments / total_no_payments) * 100
        ).toFixed(2)}%)`,
      });
      // if (isArrayWithValues(filter.branches))
      let link;
      try {
        let res = await axios({
          url: WEBSOCKET_NGROK_URL,
        });
        link = res?.data?.data;
      } catch (error) {}

      let res = await websocketApiCall({
        url: link,
        endpoint: `digital_gold/pdf_table`,
        token: await getAuthToken(),
        tenant_id: await getTenantId(),
        data: {
          table_heading: {
            title: "Advance payment received",
            subtitle: duration_range,
            // subtitle: "23 Dec, 2023 - 25 Dec 2023",
          },
          store_logo: store_details?.store_logo,
          store_name: store_details?.store_name,
          table_data: array,
          table_aggregation,
          landscape: true,
        },
        method: "POST",
      });
      console.log(res);
      if (res?.success) {
        window.open(res?.data?.link, "_blank");
        // let openRes = await openPDF({ link: res?.data?.link });
        // if (!openRes) throw new Error();
      } else throw new Error();
    } catch (error) {
      console.log(error);
      dispatch(
        setGlobalToast({
          show: true,
          message: "Unable to download PDF, please try again later.",
          severity: "error",
        })
      );
    } finally {
      handleDownloadClose();
      setPdfDownloading(false);
    }
  };

  useEffect(() => {
    if (!isArrayWithValues(customers)) return;
    setupLedger(customers);
    // if (!isArrayWithValues(orders)) return;
    // let rows = [];
    // for (let order of orders) {
    //   let obj = { ...order };
    //   let customer = {};
    //   if (isArrayWithValues(customers)) {
    //     let customerObj = customers.find((i) => i.id == obj.customer_id);
    //     if (customerObj) {
    //       let { digi_refer_obj } = customerObj;
    //       obj.digi_refer_obj = digi_refer_obj;
    //     }
    //   }

    //   let { meta_data } = order;
    //   if (isArrayWithValues(meta_data)) {
    //     for (let metaObj of meta_data) {
    //       obj[metaObj.key] = metaObj.value;
    //     }
    //   }
    //   rows.push(obj);
    // }
    // setRows(rows);
    // if (!isArrayWithValues(plans)) return;
    // let rows = [];
    // for (let plan of plans) {
    //   let {
    //     totalUsers,
    //     totalPaid,
    //     totalPayments,
    //     name,
    //     date_created,
    //     meta_data,
    //   } = plan;
    //   let metaObj = {};
    //   if (isArrayWithValues(meta_data)) {
    //     for (let obj of meta_data) {
    //       let { key, value } = obj;
    //       if (value) metaObj[key] = value;
    //     }
    //   }
    //   let obj = {
    //     totalUsers,
    //     totalPaid,
    //     totalPayments,
    //     name,
    //     duration: metaObj.digi_plan_duration,
    //   };
    //   if (date_created)
    //     obj.date_created = formatDate(
    //       new Date(date_created),
    //       "dd:mm:yyyy hh:mm"
    //     );
    //   rows.push(obj);
    // }
    // setRows(rows);
  }, [orders, customers, plan_id]);

  const onEditFilter = (payload) =>
    setFilter((state) => ({ ...state, ...payload }));

  const setupLedger = (customers) => {
    let allLedgers = [];
    for (let customer of customers) {
      let { plans } = customer;
      if (!isObjWithValues(plans)) continue;
      for (let plan of Object.values(plans)) {
        let { ledger } = plan;
        if (!isArrayWithValues(ledger)) continue;
        allLedgers = allLedgers.concat(
          [
            ...ledger.map((i, index) => ({
              plan_payment_count: index + 1,
              ...customer,
              // plan_name: plan.plan_name,
              ...plan,
              ...i,
              customerDetail: customer,
            })),
          ].filter((i) => (plan_id ? i?.product == plan_id : true))
        );
      }
    }
    allLedgers = allLedgers.sort((a, b) => {
      return new Date(b.date_paid).getTime() - new Date(a.date_paid).getTime();
    });
    console.log(allLedgers, "<<<<<<<<<<<<<<<<<<< allLedgers");
    setRows(allLedgers);
    setAllLedger(allLedgers);
  };

  const onFilterPress = () => {
    let { search, startDate, endDate, plans, branches } = filter;
    if (!isArrayWithValues(allLedgers)) return;
    let searched = [...allLedgers];
    // if (!search) return setupCustomers(customers);
    if (search) {
      searched = searched.filter((obj) => {
        let { first_name, last_name, id } = obj;

        let uniqueIds = "";
        if (isObjWithValues(obj[`digigold_uid_${store_id}`])) {
          uniqueIds = Object.values(obj[`digigold_uid_${store_id}`]).join();
        }
        let searchValue = search.toLowerCase().trim();
        let name = `${first_name}${last_name}${id}${uniqueIds}`;
        return name?.toLowerCase()?.includes(searchValue);
        // if (name?.toLowerCase()?.includes(searchValue)) searched.push(obj);
      });
    }
    if (startDate || endDate) {
      searched = searched.filter((obj) => {
        let { date_paid } = obj;

        return isDateInRange(
          new Date(date_paid),
          new Date(startDate),
          new Date(startDate).getTime === new Date(endDate).getTime
            ? new Date(
                new Date(endDate).setDate(new Date(endDate).getDate() + 1)
              )
            : new Date(endDate)
        );
        // if (name?.toLowerCase()?.includes(searchValue)) searched.push(obj);
      });
    }
    if (isArrayWithValues(plans)) {
      searched = searched.filter((obj) => {
        let { product } = obj;
        return plans.includes(product);
        // return product == plan;
        // if (name?.toLowerCase()?.includes(searchValue)) searched.push(obj);
      });
    }

    if (isArrayWithValues(branches)) {
      searched = searched.filter((obj) => {
        let { ledger } = obj;
        return ledger?.find((i) => branches.includes(i.branch_name));
      });
    }
    setRows(
      searched?.sort((a, b) => {
        let { date_paid } = a;
        let { date_paid: next_date_paid } = b;
        return (
          new Date(next_date_paid).getTime() - new Date(date_paid).getTime()
        );
      })
    );
  };
  // const onSearch = (value) => {
  //   setSearch(value);
  //   if (!isArrayWithValues(customers)) return;
  //   let searched = [];
  //   if (!value) return setupLedger(customers);
  //   for (let obj of [...customers]) {
  //     let { first_name, last_name, billing, id } = obj;
  //     let searchValue = value.toLowerCase().trim();
  //     let name = `${first_name}${last_name}${id}`;
  //     if (name?.toLowerCase()?.includes(searchValue)) searched.push(obj);
  //   }
  //   setupLedger(searched);
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const onFilterShow = () => setFilterShow((state) => !state);

  const onClearFilter = () => {
    setFilter(defaultPaymentsFilterState);
    setFilterShow(false);
  };

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent sx={{ p: 0 }}>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              sx={{ padding: "16px 10px" }}
              flexWrap="wrap"
            >
              <Stack
                direction={"row"}
                alignItems="center"
                sx={{ my: 2 }}
                spacing={2}
              >
                <SectionLabel
                  sx={{ flex: "1 1 100%" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Recent Advance Received
                </SectionLabel>
              </Stack>
              {/* <Stack
                direction="row"
                alignItems={"center"}
                justifyContent={"flex-end"}
                spacing={1}
                pr={1}
                flex={1}
              >
                <Button
                  onClick={onCSVdownload}
                  sx={{
                    // whiteSpace: "nowrap",
                    mr: 3,
                    whiteSpace: "nowrap !important",
                    overflow: "hidden",
                    padding: "3px 16px",
                  }}
                  size="small"
                  variant="outlined"
                  // endIcon={<Download />}
                >
                  Download report
                </Button>
                <IconButton onClick={onFilterShow}>
                  {filterShow ? <Close /> : <FilterAltOutlined />}
                </IconButton>
              </Stack> */}

              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent={"flex-end"}
                spacing={1}
                pr={1}
                flex={1}
              >
                <Button
                  // onClick={onCSVdownload}
                  onClick={handleDownloadClick}
                  sx={{
                    // whiteSpace: "nowrap",
                    mr: 3,
                    whiteSpace: "nowrap !important",
                    overflow: "hidden",
                    padding: "3px 16px",
                  }}
                  size="small"
                  variant="outlined"
                  // endIcon={<Download />}
                >
                  Download report
                </Button>
                <IconButton onClick={onFilterShow}>
                  {filterShow ? <Close /> : <FilterAltOutlined />}
                </IconButton>
              </Stack>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={downloadAnchorEl}
                open={downloadOpen}
                onClose={handleDownloadClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem
                  // onClick={
                  //   (productAccess.includes("add") && !addLimitReached) ||
                  //   (!productAccess.includes("add") &&
                  //     isAdmin &&
                  //     !addLimitReached)
                  //     ? onAddProduct
                  //     : null
                  // }
                  onClick={onCSVdownload}
                >
                  <ListItemIcon>
                    <DownloadOutlined fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Download as xlsx</ListItemText>
                </MenuItem>
                <MenuItem onClick={onPDFDownload}>
                  <ListItemIcon>
                    {pdfLoading ? (
                      <CircularProgress style={{ height: 18, width: 18 }} />
                    ) : (
                      <DownloadOutlined fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText>Download as PDF</ListItemText>
                </MenuItem>
              </Menu>
            </Stack>
            <Collapse in={filterShow}>
              <Box
                sx={{
                  backgroundColor: "#f7f7f7",
                  padding: "10px",
                }}
              >
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  sx={{ px: 1 }}
                  flexWrap="wrap"
                >
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ my: 2 }}
                    spacing={2}
                    flexWrap="wrap"
                  >
                    <Box>
                      <Typography sx={{ ...secondaryLabel }}>Search</Typography>
                      <TextField
                        variant="outlined"
                        // fullWidth
                        placeholder="Search customers"
                        // label="Search"
                        value={filter.search}
                        onChange={(e) =>
                          onEditFilter({ search: e.target.value })
                        }
                        size="small"
                        sx={{ mr: 2 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment>
                              <Search sx={{}} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography sx={{ ...secondaryLabel }}>Date</Typography>
                      <CustomFilterTextField
                        dateRange={filter}
                        setDateRange={onEditFilter}
                      />
                    </Box>
                    <Box>
                      <Typography sx={{ ...secondaryLabel }}>Plans</Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter.plans}
                        size="small"
                        // label="Age"
                        multiple
                        sx={{ minWidth: "100px" }}
                        onChange={(e) =>
                          onEditFilter({ plans: e.target.value })
                        }
                      >
                        {allPlans.map((i) => (
                          <MenuItem value={i.value}>{i.label}</MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Box>
                      <Typography sx={{ ...secondaryLabel }}>Branch</Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter.branches}
                        size="small"
                        // label="Age"
                        multiple
                        sx={{ minWidth: "100px" }}
                        onChange={(e) =>
                          onEditFilter({ branches: e.target.value })
                        }
                      >
                        {branches?.map((i) => (
                          <MenuItem value={i.branchName}>
                            {i.branchName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Stack>
                  <Button onClick={onClearFilter} startIcon={<Close />}>
                    Clear Filter
                  </Button>
                </Stack>
              </Box>
            </Collapse>

            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <DigiOrdersTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                  {/* {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <PaymentRow
                          row={row}
                          labelId={labelId}
                          isItemSelected={isItemSelected}
                          isDarkTheme={isDarkTheme}
                          order={order}
                          customers={customers}
                          setUserView={setUserView}
                        />
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </CardContent>
    </Card>
  );
};

const PaymentRow = ({
  row,
  isItemSelected,
  labelId,
  setUserView,
  isDarkTheme,
  order,
  customers,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isStatusOpen = Boolean(anchorEl);
  const [changingPaymentStatus, setChangingPaymentStatus] = useState(false);
  const dispatch = useDispatch();
  const store_id = getStoreId();

  const statusOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const statusClose = () => {
    setAnchorEl(null);
  };

  const onPaymentStatusChange = async (status, id) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      setChangingPaymentStatus(true);
      try {
        let res = await axios({
          url: `${website}/wp-json/wc/v3/orders/${row.order_id}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: { status },
        });
        // if (isObjWithValues(res.data)) setupOrder(res.data);
        dispatch(
          setGlobalToast({
            show: true,
            message: "Status updated successfully",
            severity: "success",
          })
        );
      } catch (error) {
        console.log(getAxiosError(error));
      }
      setChangingPaymentStatus(false);
    }
  };
  return (
    <StyledTableRow
      // hover
      onClick={(event) => row.customerDetail && setUserView(row.customerDetail)}
      sx={{ cursor: "pointer" }}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      {/* <TableCell padding="checkbox">
    <Checkbox
      color="primary"
      checked={isItemSelected}
      inputProps={{
        "aria-labelledby": labelId,
      }}
    />
  </TableCell> */}
      <TableCell
        component="th"
        id={labelId}
        scope="row"
        // padding="none"
      >
        <Typography sx={{ fontWeight: "600", fontWeight: "15px" }}>
          {/* {row?.billing?.first_name || ""}{" "}
      {row?.billing?.last_name || ""} */}
          <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
            {row?.first_name || ""} {row?.last_name || ""}
          </Typography>
          <Typography
            sx={{
              fontSize: "11px",
              color: "text.secondary",
              mt: 0.5,
            }}
          >
            Customer ID: {row.id}
          </Typography>
          {row?.[`digigold_uid_${store_id}`]?.[row.product] && (
            <Typography
              sx={{
                fontSize: "11px",
                color: "text.secondary",
                mt: 0.5,
              }}
            >
              Unique ID: {row?.[`digigold_uid_${store_id}`]?.[row.product]}
              {/* Join date:{" "}
            {row.joining_date
              ? formatDate(new Date(row.joining_date), "dd-mm-yyyy")
              : ""} */}
            </Typography>
          )}
        </Typography>
      </TableCell>
      <TableCell align="left">
        {row?.digi_refer_obj ? (
          <>
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              {row?.digi_refer_obj?.first_name || ""}{" "}
              {row?.digi_refer_obj?.last_name || ""}
            </Typography>
            <Typography
              sx={{
                fontSize: "11px",
                color: "text.secondary",
                mt: 0.5,
              }}
            >
              A/c ID: {row?.digi_refer_obj?.id}
            </Typography>
            <Typography
              sx={{
                fontSize: "11px",
                color: "text.secondary",
                mt: 0.5,
              }}
            >
              J. date:{" "}
              {row?.digi_refer_obj?.date_created
                ? formatDate(
                    new Date(row?.digi_refer_obj?.date_created),
                    "dd-mm-yyyy"
                  )
                : ""}
            </Typography>
          </>
        ) : (
          ""
        )}
      </TableCell>
      <TableCell align="right">{row?.billing?.city || ""}</TableCell>
      <TableCell align="right">
        <Tooltip title={row?.plan_name || ""}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-end"
          >
            {row?.plan_name ? reduceLargeText(row?.plan_name, 15) : "-"}
          </Stack>
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        <Typography>
          {row.date_paid
            ? formatDate(new Date(row.date_paid), "dd-mm-yyyy")
            : ""}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            mt: 0.4,
            color: "text.secondary",
          }}
        >
          {row.date_paid
            ? formatDate(new Date(row.date_paid), "hh:mm am/pm")
            : ""}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
          {`${row?.currency_symbol || ""} ${row?.total || ""}`}
        </Typography>
        <Typography
          sx={{
            fontSize: "11px",
            color: "text.secondary",
            mt: 0.5,
          }}
        >
          {row.mode}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography sx={{ fontWeight: "600", fontWeight: "15px" }}>
          {/* {row?.billing?.first_name || ""}{" "}
      {row?.billing?.last_name || ""} */}
          <Typography sx={{ fontSize: "13px" }}>
            {row.manual ? "Manual" : "Automatic"}
          </Typography>
          {/* <Typography
        sx={{
          fontSize: "11px",
          color: "text.secondary",
          mt: 0.5,
        }}
      >
        Ref. No: {row.order_id}
      </Typography> */}
          <Typography
            sx={{
              fontSize: "11px",
              color: "text.secondary",
              mt: 0.5,
            }}
          >
            {row.branch_name || ""}
          </Typography>
        </Typography>
        {/* {currency ? currencySymbols[currency] : ""} {0} */}
      </TableCell>
      <TableCell align="right">
        <Chip
          size="small"
          mr={1}
          mb={1}
          label={row.status}
          // color="warning"
          sx={{
            color: statusFontColor(row.status),
            border: isDarkTheme ? "0.7px solid" : "0px solid",
            // borderColor: statusFontColor(row.status),
            backgroundColor: isDarkTheme
              ? "transparent"
              : getStatusBackgroundColor(row.status),
          }}
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            statusOpen(e);
          }}
        />
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={isStatusOpen}
          onClose={statusClose}
          onClick={statusClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 2.2,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          {allPaymentStatus.map((i, index) => {
            return (
              <MenuItem
                key={index}
                onClick={(e) => {
                  e.stopPropagation();

                  onPaymentStatusChange(i.value, row.id);
                }}
                selected={i.value === order.status}
              >
                {i.label}
              </MenuItem>
            );
          })}
        </Menu>
      </TableCell>
    </StyledTableRow>
  );
};

const defaultFilterState = {
  search: "",
  startDate: "",
  endDate: "",
  branches: [],
  plans: [],
  only_active: false,
  plan_status: [],
};

const allPlansStatus = [
  { label: "Active", value: "active" },
  { label: "Deactive", value: "cancelled" },
  { label: "Complete", value: "all_paid" },
];
const allPlansStatusLabel = {
  active: "Active",
  cancelled: "Deactive",
  all_paid: "Complete",
};
// const allPlansStatusLabel = {
//   active: "Active",
//   cancelled: "#910905",
//   all_paid: "#1aae6f",
// };
const allPlansStatusColor = {
  active: "processing",
  cancelled: "cancelled",
  all_paid: "completed",
};
const getDefaultDateRange = () => {
  const today = new Date();
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);

  return {
    before: today.toISOString(),
    after: thirtyDaysAgo.toISOString(),
  };
};

export const CustomersTable = ({
  customers,
  plans,
  currency,
  id,
  setUserView,
  digiCancelledPlans,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(defaultFilterState);
  const [customersCSV, setCustomersCSV] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const [pdfLoading, setPdfDownloading] = useState(false);

  const theme = useTheme();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const reduxBranches = useSelector((state) => state.settings.branch);
  let store_details = useSelector((state) => state.user.store_details);

  const [downloadAnchorEl, setDownloadAnchorEl] = React.useState();
  const [openClosePlanExport, setOpenClosePlanExport] = React.useState(false);
  const [closedPlansForm, setClosedPlansForm] = useState(getDefaultDateRange());
  const downloadOpen = Boolean(downloadAnchorEl);
  const handleDownloadClick = (event) => {
    setDownloadAnchorEl(event.currentTarget);
  };
  const handleDownloadClose = () => {
    setDownloadAnchorEl(null);
  };

  let dispatch = useDispatch();

  const [branches, setBranches] = useState([]);
  useEffect(() => {
    if (isObjWithValues(reduxBranches))
      setBranches(Object.values(reduxBranches));
  }, [reduxBranches]);

  const store_id = getStoreId();

  useEffect(() => {
    if (!isArrayWithValues(plans)) return;
    setAllPlans(plans.map((i) => ({ label: i.name, value: i.id })));
  }, [plans]);

  useEffect(() => {
    onFilterPress();
  }, [filter]);

  useEffect(() => {
    if (!isArrayWithValues(rows)) return;
    let array = [];
    for (let customer of rows) {
      array.push(cus({ customer, currency, store_id, plan_id: id }));
    }
    setCustomersCSV(array);
  }, [rows]);

  function filterByTime(range, data) {
    const { before, after } = range;

    // Convert the before and after strings to timestamps
    const beforeTimestamp = new Date(before).getTime();
    const afterTimestamp = new Date(after).getTime();

    // Filter the data object
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => {
        const recordTime = value.time;
        return recordTime >= afterTimestamp && recordTime <= beforeTimestamp;
      })
    );

    return filteredData;
  }

  const onClosedPlansDownload = () => {
    try {
      let closedPlans = filterByTime(closedPlansForm, digiCancelledPlans);
      console.log(
        closedPlans,
        closedPlansForm,
        digiCancelledPlans,
        "<<<<<<<<<"
      );
      let array = getClosedPlansCSV({
        closed_plans: closedPlans,
        customers,
      });

      exportAsExcel({
        data: array,
        fileName: `Digigold Closed Plans - ${formatDate(
          new Date(),
          "dd:mm:yyyy hh:mm am/pm"
        )}`,
        sheetName: `Digigold Closed Plans`,
      });
    } catch (error) {
      console.log(error);
    } finally {
      handleDownloadClose();
    }

    // try {
    //   if (!isArrayWithValues(rows)) return;
    //   let array = [];
    //   let Amount = 0;
    //   // console.log(rows, "<<<<< rows");
    //   // return;
    //   for (let order of rows) {
    //     let row = getDigigoldLedgersCSV({ order, currency, store_id, plan_id });
    //     Amount += validateNumber(row?.Amount);
    //     array.push(row);
    //   }
    //   array.push({});
    //   array.push({ Amount });
    //   exportAsExcel({
    //     data: array,
    //     fileName: `Customer Payments - ${formatDate(
    //       new Date(),
    //       "dd:mm:yyyy hh:mm am/pm"
    //     )}`,
    //     sheetName: "Payments",
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const onCSVdownload = () => {
    try {
      handleDownloadClose();
      // csvDownload({ data: customersCSV });
      if (!isArrayWithValues(rows)) return;
      let array = [],
        totalExpected = 0,
        totalPaid = 0,
        totalMissed = 0;
      // return;
      for (let customer of rows) {
        let _rows = getDigigoldCustomerByPlansCSV({
          customer,
          currency,
          store_id,
          plan_id: id,
        });
        if (isArrayWithValues(_rows)) {
          array = array.concat?.(_rows);
          // for (let row of _rows) {
          //   totalExpected += validateNumber(row?.["Total Expected"]);
          //   totalPaid += validateNumber(row?.["Total Paid"]);
          // }
        }
        // totalExpected += validateNumber(row?.["Total Expected"]);
        // totalPaid += validateNumber(row?.["Total Paid"]);
        // totalMissed += validateNumber(row?.["Total Missed"]);
        // array.push(row);
      }
      // console.log(
      //   array?.filter((i) => i?.ID + "" === "43010"),
      //   "<<<<<< getDigigoldCustomerByPlansCSV"
      // );
      // return;
      // array.push({});
      // array.push({
      //   "Total Expected": `${
      //     currencySymbols[currency]
      //   } ${convertToIndianNumberFormat(totalExpected)}`,
      //   "Total Paid": `${
      //     currencySymbols[currency]
      //   } ${convertToIndianNumberFormat(totalPaid)}`,
      //   // "Total Missed": totalMissed,
      // });
      array = array.sort((a, b) => {
        if (a?.["Unique ID"] < b?.["Unique ID"]) {
          return -1;
        } else if (a?.["Unique ID"] > b?.["Unique ID"]) {
          return 1;
        } else {
          return 0;
        }
      });
      if (isArrayWithValues(array)) {
        let allHeaders = [];
        for (let customerRow of array) {
          for (let HeaderKey in customerRow) {
            if (!allHeaders.includes(HeaderKey)) allHeaders.push(HeaderKey);
          }
        }
        let firstObj = { ...(array?.[0] || {}) };
        allHeaders = allHeaders.sort((a, b) => {
          if (b.includes(" Amount")) return -1;
        });
        let newFirstObj = {};
        for (let key of allHeaders) {
          if (firstObj?.[key]) newFirstObj[key] = firstObj[key];
          else newFirstObj[key] = " ";
        }
        array[0] = newFirstObj;
      }

      console.log(array);

      exportAsExcel({
        data: array,
        fileName: `Digigold Customers - ${formatDate(
          new Date(),
          "dd:mm:yyyy hh:mm am/pm"
        )}`,
        sheetName: `Customers`,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onPDFDownload = async () => {
    setPdfDownloading(true);
    try {
      // await sleep(2000);
      if (!isArrayWithValues(rows)) return;
      let array = [],
        totalExpected = 0,
        totalPaid = 0,
        totalMissed = 0,
        total_plans = [];
      // return;
      let index = 0,
        duration_range = "";

      if (filter?.startDate && filter?.endDate)
        duration_range = `${formatDate(
          new Date(filter?.startDate),
          "yyyy-mm-dd"
        )} - ${formatDate(new Date(filter?.endDate), "yyyy-mm-dd")}`;
      for (let customer of rows) {
        let { plans } = customer;

        let obj = getDigigoldCustomerPDF({
          customer,
          currency,
          store_id,
          plan_id: id,
          index,
        });
        let row = {
          // "SR No.": index,
          ...obj,
        };
        row.Remarks = " ";

        totalExpected += validateNumber(
          row?.["Total Expected"]
            ? getNumberFromString(row?.["Total Expected"] || "")
            : ""
        );
        totalPaid += validateNumber(
          row?.["Total Paid"]?.title
            ? getNumberFromString(row?.["Total Paid"]?.title || "")
            : ""
        );
        totalMissed += validateNumber(
          row?.["Total Missed"]
            ? getNumberFromString(row?.["Total Missed"] || "")
            : ""
        );
        total_plans = [...new Set(total_plans.concat(Object.keys(plans)))];
        array.push(row);
        index++;
      }
      console.log(array, "<<<<<<<<<< 1");
      // array.push({});
      // array.push({
      //   "Total Expected": totalExpected,
      //   "Total Paid": totalPaid,
      //   "Total Missed": totalMissed,
      // });
      array = array.sort((a, b) => {
        if (a?.["Unique ID"] < b?.["Unique ID"]) {
          return -1;
        } else if (a?.["Unique ID"] > b?.["Unique ID"]) {
          return 1;
        } else {
          return 0;
        }
      });
      if (isArrayWithValues(array)) {
        let allHeaders = [];
        for (let customerRow of array) {
          for (let HeaderKey in customerRow) {
            if (!allHeaders.includes(HeaderKey)) allHeaders.push(HeaderKey);
          }
        }
        let firstObj = { ...(array?.[0] || {}) };
        allHeaders = allHeaders.sort((a, b) => {
          if (b.includes(" Amount")) return -1;
        });
        let newFirstObj = {};
        for (let key of allHeaders) {
          if (firstObj?.[key]) newFirstObj[key] = firstObj[key];
          else newFirstObj[key] = " ";
        }
        array[0] = newFirstObj;
      }

      console.log(array, "<<<<<<<<<< ");
      let table_aggregation = [];

      let filtersAggregation = {};
      if (isArrayWithValues(filter.branches))
        filtersAggregation["Branch"] = filter.branches?.join(", ");
      if (isArrayWithValues(filter.plans)) {
        filtersAggregation["Total Plans"] = filter.plans
          ?.map((i) => {
            let plan = allPlans.find((j) => `${j.value}` === `${i}`);
            if (plan?.label) return plan.label;
          })
          ?.filter(Boolean)
          ?.join(", ");
      }
      table_aggregation.push(filtersAggregation);

      table_aggregation.push({
        "Total Customers": rows?.length || "",
        Plans: total_plans?.length || "",
      });
      table_aggregation.push({
        Expected: `${currencySymbols[currency]} ${convertToIndianNumberFormat(
          totalExpected
        )}`,
        Paid: `${currencySymbols[currency]} ${convertToIndianNumberFormat(
          totalPaid
        )}`,
        Missed: `${currencySymbols[currency]} ${convertToIndianNumberFormat(
          totalMissed
        )}`,
      });
      let link;
      try {
        let res = await axios({
          url: WEBSOCKET_NGROK_URL,
        });
        link = res?.data?.data;
      } catch (error) {}
      let res = await websocketApiCall({
        url: link,
        endpoint: `digital_gold/pdf_table`,
        token: await getAuthToken(),
        tenant_id: await getTenantId(),
        data: {
          table_heading: {
            title: "Digigold Customers",
            subtitle: duration_range,
          },
          store_logo: store_details?.store_logo,
          store_name: store_details?.store_name,
          table_data: array,
          table_aggregation,
          landscape: true,
        },
        method: "POST",
      });
      if (res?.success) {
        window.open(res?.data?.link, "_blank");
        // let openRes = await openPDF({ link: res?.data?.link });
        // if (!openRes) throw new Error();
      } else throw new Error();
    } catch (error) {
      console.log(error);
      dispatch(
        setGlobalToast({
          show: true,
          message: "Unable to download PDF, please try again later.",
          severity: "error",
        })
      );
    } finally {
      handleDownloadClose();
      setPdfDownloading(false);
    }
  };

  const onEditFilter = (payload) =>
    setFilter((state) => ({ ...state, ...payload }));

  useEffect(() => {
    if (!isArrayWithValues(customers)) return;
    setupCustomers(customers);
    // if (!isArrayWithValues(plans)) return;
    // let rows = [];
    // for (let plan of plans) {
    //   let {
    //     totalUsers,
    //     totalPaid,
    //     totalPayments,
    //     name,
    //     date_created,
    //     meta_data,
    //   } = plan;
    //   let metaObj = {};
    //   if (isArrayWithValues(meta_data)) {
    //     for (let obj of meta_data) {
    //       let { key, value } = obj;
    //       if (value) metaObj[key] = value;
    //     }
    //   }
    //   let obj = {
    //     totalUsers,
    //     totalPaid,
    //     totalPayments,
    //     name,
    //     duration: metaObj.digi_plan_duration,
    //   };
    //   if (date_created)
    //     obj.date_created = formatDate(
    //       new Date(date_created),
    //       "dd:mm:yyyy hh:mm"
    //     );
    //   rows.push(obj);
    // }
    // setRows(rows);
  }, [customers, digiCancelledPlans, plans, id]);

  const setupCustomers = (customers) => {
    if (!isArrayWithValues(customers)) return setRows([]);
    let allCustomers = [...customers];
    if (id) allCustomers = customers.filter((i) => Boolean(i.plans[`${id}`]));

    allCustomers = allCustomers.map((i) => {
      if (isObjWithValues(digiCancelledPlans)) {
        let { id, plans } = i;
        let plan_status = "active";
        let cancelledPlanKeys = Object.keys(digiCancelledPlans);
        let totalPlans = Object.keys(plans)?.length;
        let totalCancelled = 0;
        Object.values(plans).map((j) => {
          let cancelled_plan_key = j?.is_cancelled;
          if (cancelledPlanKeys?.includes(cancelled_plan_key)) totalCancelled++;
        });
        // Object.keys(plans).map((j) => {
        //   // if (digiCancelledPlans?.[`${j}_${id}`]) plan_status = "cancelled";
        //   if (cancelledPlanKeys?.find((i) => i?.includes(`${j}_${id}`)))
        //     totalCancelled++;
        //   // plan_status = "cancelled";
        // });
        if (totalPlans === totalCancelled) plan_status = "cancelled";
        // if (!isObjWithValues(digiCancelledPlans)) plan_status = true;
        if (checkUserAllPaymentsModeInDigigold({ plans }))
          plan_status = "all_paid";

        return { ...i, plan_status };
      }
      return { ...i, plan_status: "active" };
    });

    setRows(
      allCustomers?.sort((a, b) => {
        return (
          new Date(b.date_created).getTime() -
          new Date(a.date_created).getTime()
        );
      })
    );
  };
  const onFilterPress = () => {
    let { search, startDate, endDate, plans, branches, plan_status } = filter;
    if (!isArrayWithValues(customers)) return;
    let searched = [...customers];
    // if (!search) return setupCustomers(customers);
    if (search) {
      searched = searched.filter((obj) => {
        let { first_name, last_name, billing, id } = obj;

        let uniqueIds = "";
        if (isObjWithValues(obj[`digigold_uid_${store_id}`])) {
          uniqueIds = Object.values(obj[`digigold_uid_${store_id}`]).join();
        }
        let searchValue = search.toLowerCase().trim();
        let name = `${first_name}${last_name}${id}${uniqueIds}`;
        return name?.toLowerCase()?.includes(searchValue);
        // if (name?.toLowerCase()?.includes(searchValue)) searched.push(obj);
      });
    }
    // for (let obj of [...customers]) {
    //   let { first_name, last_name, billing, id } = obj;

    //   let uniqueIds = "";
    //   if (isObjWithValues(obj[`digigold_uid_${store_id}`])) {
    //     uniqueIds = Object.values(obj[`digigold_uid_${store_id}`]).join();
    //   }
    //   let searchValue = search.toLowerCase().trim();
    //   let name = `${first_name}${last_name}${id}${uniqueIds}`;
    //   if (name?.toLowerCase()?.includes(searchValue)) searched.push(obj);
    // }
    // else search = [...customers];
    if (startDate || endDate) {
      searched = searched.filter((obj) => {
        let { date_created, joining_date } = obj;

        return isDateInRange(
          new Date(joining_date),
          new Date(startDate),
          new Date(startDate).getTime === new Date(endDate).getTime
            ? new Date(
                new Date(endDate).setDate(new Date(endDate).getDate() + 1)
              )
            : new Date(endDate)
        );
        // if (name?.toLowerCase()?.includes(searchValue)) searched.push(obj);
      });
    }
    if (isArrayWithValues(plans)) {
      searched = searched.filter((obj) => {
        let { plans: userPlans } = obj;

        return plans.some((i) =>
          Object.values(userPlans)?.find((j) => j?.product + "" === i + "")
        );
        // return plans.some((i) => Boolean(userPlans[i]));
        // if (name?.toLowerCase()?.includes(searchValue)) searched.push(obj);
      });
    }
    if (isArrayWithValues(branches)) {
      searched = searched.filter((obj) => {
        return branches?.some((i) => i == obj?.branch);
        // if (name?.toLowerCase()?.includes(searchValue)) searched.push(obj);
      });
    }

    if (isArrayWithValues(plan_status)) {
      searched = searched.filter((obj) => {
        let customer = rows?.find((i) => i.id === obj.id);
        return plan_status.includes(customer?.plan_status);
        // if (name?.toLowerCase()?.includes(searchValue)) searched.push(obj);
      });
    }
    // if (only_active) {
    //   searched = searched.filter((obj) => {
    //     let customer = rows?.find((i) => i.id === obj.id);
    //     return Boolean(customer?.plansActive);
    //     // if (name?.toLowerCase()?.includes(searchValue)) searched.push(obj);
    //   });
    // }
    // if ((startDate || endDate) && plan) {
    //   searched = searched.filter((obj) => {
    //     let { plans } = obj;
    //     if (isObjWithValues(plans[plan])) {
    //       let { joining_date } = plans[plan];
    //       return isDateInRange(
    //         new Date(joining_date),
    //         new Date(startDate),
    //         endDate ? new Date(endDate) : new Date()
    //       );
    //     }

    //     // if (name?.toLowerCase()?.includes(searchValue)) searched.push(obj);
    //   });
    // }
    setupCustomers(searched);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const onFilterShow = () => setFilterShow((state) => !state);

  const onClearFilter = () => {
    setFilter(defaultFilterState);
    setFilterShow(false);
  };

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent sx={{ p: 0 }}>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              sx={{ padding: "16px 10px" }}
              flexWrap="wrap"
            >
              <Stack
                direction={"row"}
                alignItems="center"
                sx={{ my: 2 }}
                spacing={2}
              >
                <SectionLabel
                  sx={{ flex: "1 1 100%" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Digi Gold Customers
                </SectionLabel>
              </Stack>
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent={"flex-end"}
                spacing={1}
                pr={1}
                flex={1}
              >
                <Button
                  // onClick={onCSVdownload}
                  onClick={handleDownloadClick}
                  sx={{
                    // whiteSpace: "nowrap",
                    mr: 3,
                    whiteSpace: "nowrap !important",
                    overflow: "hidden",
                    padding: "3px 16px",
                  }}
                  size="small"
                  variant="outlined"
                  // endIcon={<Download />}
                >
                  Download report
                </Button>
                <IconButton onClick={onFilterShow}>
                  {filterShow ? <Close /> : <FilterAltOutlined />}
                </IconButton>
              </Stack>
            </Stack>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={downloadAnchorEl}
              open={downloadOpen}
              onClose={handleDownloadClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                // onClick={
                //   (productAccess.includes("add") && !addLimitReached) ||
                //   (!productAccess.includes("add") &&
                //     isAdmin &&
                //     !addLimitReached)
                //     ? onAddProduct
                //     : null
                // }
                onClick={onCSVdownload}
              >
                <ListItemIcon>
                  <DownloadOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>Download as xlsx</ListItemText>
              </MenuItem>
              <MenuItem onClick={onPDFDownload}>
                <ListItemIcon>
                  {pdfLoading ? (
                    <CircularProgress style={{ height: 18, width: 18 }} />
                  ) : (
                    <DownloadOutlined fontSize="small" />
                  )}
                </ListItemIcon>
                <ListItemText>Download as PDF</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => setOpenClosePlanExport(true)}>
                <ListItemIcon>
                  {pdfLoading ? (
                    <CircularProgress style={{ height: 18, width: 18 }} />
                  ) : (
                    <DownloadOutlined fontSize="small" />
                  )}
                </ListItemIcon>
                <ListItemText>Download as xlsx (Closed Plans)</ListItemText>
              </MenuItem>
            </Menu>
            {/* <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(selected.length > 0 && {
                  bgcolor: (theme) =>
                    alpha(
                      theme.palette.primary.main,
                      theme.palette.action.activatedOpacity
                    ),
                }),
                flexWrap: "wrap",
              }}
            >
              {selected.length > 0 ? (
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selected.length} selected
                </Typography>
              ) : (
                <SectionLabel
                  sx={{ flex: "1 1 100%" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Digi Gold Customers
                </SectionLabel>
              )}
              {!isIpad || true ? (
                <Button
                  onClick={onCSVdownload}
                  sx={{
                    // whiteSpace: "nowrap",
                    mr: 3,
                    whiteSpace: "nowrap !important",
                    overflow: "hidden",
                    padding: "8px 16px",
                  }}
                  size="small"
                  variant="outlined"
                  // endIcon={<Download />}
                >
                  Download excel
                </Button>
              ) : null}
            </Toolbar> */}
            {/* {isIpad ? (
              <Stack direction={"row"} justifyContent="flex-end" sx={{ my: 2 }}>
                <Button
                  onClick={onCSVdownload}
                  sx={{
                    // whiteSpace: "nowrap",
                    mr: 3,
                    whiteSpace: "nowrap !important",
                    overflow: "hidden",
                    padding: "0 16px",
                  }}
                  size="small"
                  variant="outlined"
                  // endIcon={<Download />}
                >
                  Download excel
                </Button>
              </Stack>
            ) : null} */}
            <Collapse in={filterShow}>
              <Box
                sx={{
                  backgroundColor: "#f7f7f7",
                  padding: "10px",
                }}
              >
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  sx={{ px: 1 }}
                  flexWrap="wrap"
                >
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ my: 2 }}
                    spacing={2}
                    flexWrap="wrap"
                  >
                    <Box>
                      <Typography sx={{ ...secondaryLabel }}>Search</Typography>
                      <TextField
                        variant="outlined"
                        // fullWidth
                        placeholder="Search customers"
                        // label="Search"
                        value={filter.search}
                        onChange={(e) =>
                          onEditFilter({ search: e.target.value })
                        }
                        size="small"
                        sx={{ mr: 2 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment>
                              <Search sx={{}} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography sx={{ ...secondaryLabel }}>Date</Typography>
                      <CustomFilterTextField
                        dateRange={filter}
                        setDateRange={onEditFilter}
                      />
                    </Box>
                    <Box>
                      <Typography sx={{ ...secondaryLabel }}>Plans</Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter.plans}
                        size="small"
                        // label="Age"
                        multiple
                        sx={{ minWidth: "100px" }}
                        onChange={(e) =>
                          onEditFilter({ plans: e.target.value })
                        }
                      >
                        {allPlans.map((i) => (
                          <MenuItem value={i.value}>{i.label}</MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Box>
                      <Typography sx={{ ...secondaryLabel }}>Branch</Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter.branches}
                        size="small"
                        // label="Age"
                        multiple
                        sx={{ minWidth: "100px" }}
                        onChange={(e) =>
                          onEditFilter({ branches: e.target.value })
                        }
                      >
                        {branches?.map((i) => (
                          <MenuItem value={i.branchName}>
                            {i.branchName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Box>
                      <Typography sx={{ ...secondaryLabel }}>Status</Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter.plan_status}
                        size="small"
                        // label="Age"
                        multiple
                        sx={{ minWidth: "100px" }}
                        onChange={(e) =>
                          onEditFilter({ plan_status: e.target.value })
                        }
                      >
                        {allPlansStatus?.map((i) => (
                          <MenuItem value={i.value}>{i.label}</MenuItem>
                        ))}
                      </Select>
                    </Box>
                    {/* <Box>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={filter.only_active}
                        onChange={(e) =>
                          onEditFilter({ only_active: e.target.checked })
                        }
                        label="Only Active"
                        sx={{ mt: 2 }}
                      />
                    </Box> */}
                  </Stack>
                  <Button onClick={onClearFilter} startIcon={<Close />}>
                    Clear Filter
                  </Button>
                </Stack>
              </Box>
            </Collapse>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <CustomersTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                  {/* {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      let firstPayment = getFirstPayment(row.plans);
                      return (
                        <StyledTableRow
                          // hover
                          onClick={(event) => {
                            setUserView && setUserView(row);
                            setTimeout(() => {
                              window.scrollTo({ top: 0, behavior: "smooth" });
                            }, 300);
                          }}
                          sx={{ cursor: "pointer" }}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell> */}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "500" }}
                            >
                              {row.first_name || ""} {row.last_name || ""}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "text.secondary",
                                mt: 0.5,
                              }}
                            >
                              Customer ID: {row.id}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "text.secondary",
                                mt: 0.5,
                              }}
                            >
                              Join date:{" "}
                              {row.joining_date
                                ? formatDate(
                                    new Date(row.joining_date),
                                    "dd-mm-yyyy"
                                  )
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            {row?.digi_refer_obj ? (
                              <>
                                <Typography
                                  sx={{ fontSize: "14px", fontWeight: "500" }}
                                >
                                  {row?.digi_refer_obj?.first_name || ""}{" "}
                                  {row?.digi_refer_obj?.last_name || ""}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    color: "text.secondary",
                                    mt: 0.5,
                                  }}
                                >
                                  A/c ID: {row?.digi_refer_obj?.id}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    color: "text.secondary",
                                    mt: 0.5,
                                  }}
                                >
                                  J. date:{" "}
                                  {row?.digi_refer_obj?.date_created
                                    ? formatDate(
                                        new Date(
                                          row?.digi_refer_obj?.date_created
                                        ),
                                        "dd-mm-yyyy"
                                      )
                                    : ""}
                                </Typography>
                              </>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {row?.billing?.city || ""}
                          </TableCell>
                          <TableCell align="left">
                            <Stack
                              direction="row"
                              alignItems={"center"}
                              justifyContent="flex-start"
                            >
                              <Box>
                                <Tooltip
                                  title={
                                    row?.plans
                                      ? Object.values(row?.plans).map((i) => (
                                          <Typography sx={{ fontSize: "12px" }}>
                                            {i.plan_name}
                                          </Typography>
                                        ))
                                      : // .join("\n")
                                        "-"
                                  }
                                >
                                  <>
                                    {Object.values(row?.plans)
                                      ?.splice(0, 2)
                                      ?.map((i) => (
                                        <Typography sx={{ fontSize: "12px" }}>
                                          {i.plan_name}
                                        </Typography>
                                      ))}
                                  </>
                                </Tooltip>
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    color: "#72727299",
                                  }}
                                >
                                  {isObjWithValues(row?.plans)
                                    ? Object.keys(row?.plans).length > 1
                                      ? `${
                                          Object.keys(row?.plans).length
                                        } Plans`
                                      : `${Object.keys(row?.plans).length} Plan`
                                    : "-"}
                                </Typography>
                              </Box>
                              {/* <Tooltip
                                title={
                                  isObjWithValues(row?.plans)
                                    ? Object.values(row?.plans).map((i) => (
                                        <Typography sx={{ fontSize: "12px" }}>
                                          {i.plan_name}
                                        </Typography>
                                      ))
                                    : // .join("\n")
                                      "-"
                                }
                              >
                                <Info
                                  style={{
                                    fill: "#bcbcbc",
                                    height: "15px",
                                    width: "15px",
                                    marginLeft: 5,
                                  }}
                                />
                              </Tooltip> */}
                            </Stack>
                          </TableCell>
                          <TableCell align="right">{"-"}</TableCell>
                          <TableCell align="right">
                            {firstPayment?.collector || "-"}
                          </TableCell>
                          <TableCell align="right">
                            <CustomChip
                              label={allPlansStatusLabel[row.plan_status]}
                              type={allPlansStatusColor[row.plan_status]}
                            />
                            {/* <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label={allPlansStatusLabel[row.plansActive]}
                              // color="warning"
                              // onClick={() => setGiftFormOpen(true)}
                              sx={{
                                color: allPlansStatusColor[row.plansActive],
                                border: isDarkTheme
                                  ? "0.7px solid"
                                  : "0px solid",
                                // borderColor: statusFontColor(row.status),
                                cursor: "pointer",
                                backgroundColor: isDarkTheme
                                  ? "transparent"
                                  : allPlansStatusBGColor[row.plansActive]
                                  ? "#e0f6ed"
                                  : "#f2e5e8",
                              }}
                              variant="outlined"
                            /> */}
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {currency && row.totalExpected
                                ? currencySymbols[currency]
                                : ""}{" "}
                              {row.totalExpected || "N/A"}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "text.secondary",
                                // textAlign: "center",
                              }}
                            >
                              {row.totalExpectedContribution || 0} %
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {currency && row.totalPaid
                                ? currencySymbols[currency]
                                : ""}{" "}
                              {row.totalPaid || "N/A"}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "text.secondary",
                                // textAlign: "center",
                              }}
                            >
                              {row.totalPaidContribution || 0} %
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {currency && row.totalMissed
                                ? currencySymbols[currency]
                                : ""}{" "}
                              {row.totalMissed || "N/A"}
                            </Typography>
                            {/* <Typography
                              sx={{
                                fontSize: "11px",
                                color: "text.secondary",
                                // textAlign: "center",
                              }}
                            >
                              {row.totalPaidContribution || 0} %
                            </Typography> */}
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
        <Dialog
          open={openClosePlanExport}
          onClose={() => setOpenClosePlanExport(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            Download closed plans sheet
          </DialogTitle>
          <DialogContent>
            <HorizontalStack sx={{ mt: 4 }} justifyContent="space-between">
              <PrimaryLabelSmall>Select Date: </PrimaryLabelSmall>
              <CustomDateRangeFilter
                dateRange={closedPlansForm}
                startDateLabel={"after"}
                endDateLabel={"before"}
                setDateRange={
                  (dateRange) => setClosedPlansForm({ ...dateRange })
                  // onDateSubmit({ ...defaultParams, ...params, ...dateRange })
                }
                // allowed_filters={[
                //   "this_week",
                //   "this_month",
                //   "this_year",
                //   "custom",
                // ]}
                mobileMode
                // selectedValue={"this_week"}
                custom_only
              />
            </HorizontalStack>
            {/* <HorizontalStack sx={{ mt: 4 }} justifyContent="space-between">
              <PrimaryLabelSmall>Select Status: </PrimaryLabelSmall>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={form.status}
                // label="Age"
                onChange={(e) => editForm({ status: e.target.value })}
                size="small"
              >
                {orderStatuses?.map((i) => (
                  <MenuItem value={i?.value}>{i?.label}</MenuItem>
                ))}
              </Select>
            </HorizontalStack> */}
          </DialogContent>
          {/* {Boolean(error) && (
            <ErrorLabelExtraSmall sx={{ textAlign: "center" }}>
              <strong>{error}</strong>
            </ErrorLabelExtraSmall>
          )} */}
          <DialogActions>
            <Button
              onClick={() => setOpenClosePlanExport(false)}
              // onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              // loading={loading}
              onClick={onClosedPlansDownload}
              variant="contained"
            >
              Start Download
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

function getFirstPayment(planObject) {
  let firstPayment = null;
  let collector = null;

  for (const planId in planObject) {
    const ledger = planObject[planId].ledger;
    for (const payment of ledger) {
      if (payment.status === "completed") {
        if (!firstPayment) {
          firstPayment = payment;
          collector = payment.collected_by;
        } else if (
          new Date(payment.date_paid) < new Date(firstPayment.date_paid)
        ) {
          firstPayment = payment;
          collector = payment.collected_by;
        }
      }
    }
  }

  return { firstPayment, collector };
}

export const PlansTable = ({ customers, plans, currency, setPlanView }) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!isArrayWithValues(plans)) return;
    setupPlans(plans);
  }, [customers, plans]);

  const setupPlans = (plans) => {
    let rows = [];
    for (let plan of plans) {
      let {
        totalUsers,
        totalPaid,
        totalPayments,
        totalExpected,
        name,
        date_created,
        meta_data,
        totalMissed,
        price,
        totalPlanContribution,
        id,
      } = plan;
      let metaObj = {};
      if (isArrayWithValues(meta_data)) {
        for (let obj of meta_data) {
          let { key, value } = obj;
          if (value) metaObj[key] = value;
        }
      }
      let obj = {
        id,
        totalUsers,
        totalPaid,
        totalPayments,
        name,
        totalExpected,
        duration: metaObj.digi_plan_duration,
        ...metaObj,
        price,
        totalMissed,
        totalPlanContribution,
      };
      if (date_created)
        obj.date_created = formatDate(new Date(date_created), "dd/mm/yyyy");
      rows.push(obj);
    }
    setRows(rows);
  };

  const onSearch = (value) => {
    setSearch(value);
    if (!isArrayWithValues(plans)) return;
    let searched = [];
    if (!value) return setupPlans(plans);
    for (let obj of [...plans]) {
      let { name } = obj;
      let searchValue = value.toLowerCase().trim();
      // let name = `${first_name}${last_name}`;
      if (name?.toLowerCase()?.includes(searchValue)) searched.push(obj);
    }
    setupPlans(searched);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              search={search}
              onSearch={onSearch}
            />
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                  {/* {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <StyledTableRow
                          // hover
                          onClick={() => setPlanView && setPlanView(row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell> */}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                            sx={{ fontWeight: "500" }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "500" }}
                            >
                              {row.name}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "12px", color: "text.secondary" }}
                            >
                              Launch on: {row.date_created}
                            </Typography>
                          </TableCell>
                          {row.digi_plan_type === "amount" ? (
                            <TableCell align="right" sx={{ fontWeight: "600" }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                }}
                              >
                                {currencySymbols[currency]} {row.price}/
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  color: "text.secondary",
                                  fontWeight: "500",
                                }}
                              >
                                month
                              </Typography>
                            </TableCell>
                          ) : row.digi_plan_type === "grams" ? (
                            <TableCell
                              align="right"
                              sx={{
                                fontSize: "13px",

                                whiteSpace: "nowrap",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                }}
                              >
                                {row.plan_total_grams} Grams
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  color: "text.secondary",
                                  fontWeight: "500",
                                }}
                              >
                                (
                                {validateNumber(
                                  Number(
                                    Number(row.plan_total_grams) /
                                      Number(row.digi_plan_duration)
                                  ).toFixed(3)
                                )}{" "}
                                grams/
                                <br /> month)
                              </Typography>
                            </TableCell>
                          ) : row.digi_plan_type === "flexi" ? (
                            <TableCell
                              align="right"
                              sx={{
                                fontSize: "13px",

                                whiteSpace: "nowrap",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                }}
                              >
                                {currencySymbols[currency]} {row.price}
                              </Typography>
                            </TableCell>
                          ) : null}
                          <TableCell align="right">
                            {row.duration} Months
                          </TableCell>
                          <TableCell align="right">{row.totalUsers}</TableCell>
                          <TableCell align="right">
                            {row.digi_plan_type === "flexi"
                              ? "N/A"
                              : validateNumber(
                                  Math.round(
                                    Number(row.totalUsers) *
                                      Number(row.digi_plan_duration)
                                  )
                                )}
                          </TableCell>
                          <TableCell align="right">
                            {currency && row.totalExpected
                              ? currencySymbols[currency]
                              : ""}{" "}
                            {row.totalExpected || "N/A"}
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {currency && row.totalPaid
                                ? currencySymbols[currency]
                                : ""}{" "}
                              {row.totalPaid || "N/A"}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "text.secondary",
                                // textAlign: "center",
                              }}
                            >
                              {validateNumber(
                                Math.round(
                                  (Number(row.totalPaid) /
                                    Number(row.totalExpected)) *
                                    100
                                ).toFixed(2)
                              )}
                              %
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {currency && row.totalMissed
                                ? currencySymbols[currency]
                                : ""}{" "}
                              {row.totalMissed || "N/A"}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "text.secondary",
                                // textAlign: "center",
                              }}
                            >
                              {validateNumber(
                                Math.round(
                                  (Number(row.totalMissed) /
                                    Number(row.totalExpected)) *
                                    100
                                ).toFixed(2)
                              )}
                              %
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {row.totalPlanContribution} %
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </CardContent>
    </Card>
  );
};

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

// CustomerTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

const DigiOrdersTableHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Customer Name",
  },
  {
    id: "family_name",
    numeric: false,
    disablePadding: false,
    // label: "Account/ Family Name",
    label: "Refer by",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "plan_name",
    numeric: true,
    disablePadding: false,
    label: "Plan Name",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "amt_paid",
    numeric: true,
    disablePadding: false,
    label: "Amt Paid",
  },
  {
    id: "payment_mode",
    numeric: true,
    disablePadding: false,
    label: "Payment mode",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function DigiOrdersTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {DigiOrdersTableHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

DigiOrdersTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const customerTableHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Customer Name",
  },
  {
    id: "family_name",
    numeric: false,
    disablePadding: false,
    // label: "Family Name",
    label: "Refer by",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "plans_enrolled",

    numeric: false,
    disablePadding: false,
    label: "Plans Enrolled",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Enrolled Via",
  },
  {
    id: "enroll_by",
    numeric: true,
    disablePadding: false,
    label: "Enroll by",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "amt_expected",
    numeric: true,
    disablePadding: false,
    label: "Amt Expected",
  },
  {
    id: "amt_paid",
    numeric: true,
    disablePadding: false,
    label: "Amt Paid",
  },
  {
    id: "amt_missed",
    numeric: true,
    disablePadding: false,
    label: "Amt Missed",
  },
];

function CustomersTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {customerTableHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CustomersTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Plan Name",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "duration",
    numeric: true,
    disablePadding: false,
    label: "Duration",
  },
  {
    id: "customers",
    numeric: true,
    disablePadding: false,
    label: "Customers",
  },
  {
    id: "no_of_payments",
    numeric: true,
    disablePadding: false,
    label: "Nos of Payments",
  },
  {
    id: "expected_collections",
    numeric: true,
    disablePadding: false,
    label: "Expected Collections",
  },
  {
    id: "received_collections",
    numeric: true,
    disablePadding: false,
    label: "Received Collections",
  },
  {
    id: "misssed_collections",
    numeric: true,
    disablePadding: false,
    label: "Missed Collections",
  },
  {
    id: "contribution",
    numeric: true,
    disablePadding: false,
    label: "Contribution",
  },
];
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, onSearch, search } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <SectionLabel
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Digi Gold Plans
        </SectionLabel>
      )}
      <TextField
        variant="standard"
        // fullWidth
        placeholder="search"
        value={search}
        onChange={(e) => onSearch(e.target.value)}
        size="small"
        sx={{ mr: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment>
              <Search sx={{ mr: 1, mb: 1 }} />
            </InputAdornment>
          ),
        }}
      />
      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Delete />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterList />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export const PlansContributions = ({ customers, plans, showFilter = true }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    series: [],
    series2: [],
  });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  // useEffect(() => {}, [plans])

  useEffect(() => {
    if (!isArrayWithValues(customers)) return;
    let dataObj = {};
    for (let customer of customers) {
      let { plans } = customer;
      // if (!isArrayWithValues(plans)) continue;
      if (!isObjWithValues(plans)) continue;
      for (let planObj of Object.values(plans)) {
        let { plan_name, ledger } = planObj;
        if (!dataObj[plan_name]) dataObj[plan_name] = 0;
        if (!isArrayWithValues(ledger)) continue;
        for (let ledgerObj of ledger) {
          let { total, date_paid } = ledgerObj;
          if (dateRange.startDate && dateRange.endDate) {
            let from = new Date(dateRange.startDate).getTime();
            let to = new Date(dateRange.endDate).getTime();
            let paidTime = new Date(date_paid).getTime();
            if (from < paidTime && paidTime < to)
              dataObj[plan_name] += Math.round(total);
          } else dataObj[plan_name] += Math.round(total);
        }
      }
    }
    setChartData({
      labels: Object.keys(dataObj),
      series: Object.values(dataObj),
    });
  }, [customers, dateRange]);

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <SectionLabel>Plans Contributions</SectionLabel>
          {showFilter ? (
            <CustomDateRangeFilter
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          ) : null}
        </Stack>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactApexChart
            options={{
              ...plansContributionsPieOptions,
              labels: chartData.labels,
            }}
            series={chartData.series}
            type="pie"
            width={550}
            height={500}
          />
        </Box>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactApexChart
            options={{
              ...plansContributionsPieOptions,
              labels: chartData.labels,
            }}
            series={chartData.series2}
            type="pie"
            width={550}
            height={500}
          />
        </Box> */}
      </CardContent>
    </Card>
  );
};

const CustomerEnrolledGraph = ({ topUsersPlans: _topUsersPlans }) => {
  const [maxTotal, setMaxTotal] = useState(0);
  const [colors, setColors] = useState([]);
  const [topUsersPlans, setTopUsersPlans] = useState([]);

  useEffect(() => {
    if (isArrayWithValues(_topUsersPlans))
      setTopUsersPlans(_topUsersPlans?.slice(0, 6));
  }, [_topUsersPlans]);

  useEffect(() => {
    if (isArrayWithValues(topUsersPlans)) {
      setMaxTotal(topUsersPlans[0].totalUsers);
      let colors = [];
      topUsersPlans.map((i) =>
        colors.push(
          digigoldGlobalColors[getRandomInt(digigoldGlobalColors.length)]
        )
      );
      setColors(colors);
    }
  }, [topUsersPlans]);

  return (
    <>
      <Card sx={{ borderRadius: "15px", mt: 4, height: "100%" }}>
        <CardContent>
          <SectionLabel sx={{}}>Customers Enrolled</SectionLabel>
          <Box sx={{ marginTop: "auto", height: "100%" }}>
            {isArrayWithValues(topUsersPlans) &&
              topUsersPlans
                .filter((i) => i.totalUsers)
                .map((plan, index) => {
                  let { totalUsers, name } = plan;
                  return (
                    <Box
                      sx={{
                        height: "45px",
                        width: `${
                          (validateNumber(totalUsers) / maxTotal) * 100
                        }%`,
                        borderRadius: "10px",
                        //   backgroundColor: "#25D366",
                        backgroundColor: colors[index],
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        margin: "18px 0px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          verticalAlign: "center",
                          fontWeight: "600",
                          color: "#fff",
                          margin: "auto 0px",
                        }}
                      >
                        {(validateNumber(totalUsers) / maxTotal) * 100 > 30
                          ? `${name}: ${totalUsers}`
                          : (validateNumber(totalUsers) / maxTotal) * 100 < 30
                          ? totalUsers
                          : ""}
                      </Typography>
                    </Box>
                  );
                })}

            <Divider sx={{ my: 2 }} />
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="space-around"
              sx={{ mt: 5 }}
            >
              {isArrayWithValues(topUsersPlans) &&
                topUsersPlans
                  .filter((i) => i.totalUsers)
                  .map((plan, index) => {
                    let { totalUsers, name } = plan;
                    return (
                      <Tooltip title={name}>
                        <Box sx={{ textAlign: "center" }}>
                          <Box
                            sx={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "50%",
                              backgroundColor: colors[index],
                              //   backgroundColor: "#e8e8e8",
                              margin: "0px auto",
                            }}
                          />
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: "500", mt: 2 }}
                          >
                            {name && name.length > 10
                              ? name.substring(0, 10) + "..."
                              : name}
                          </Typography>
                        </Box>
                      </Tooltip>
                    );
                  })}
              {/* <Box sx={{ textAlign: "center" }}>
            <Whatsapp />
            <Typography sx={{ fontSize: "12px", fontWeight: "500", mt: 1 }}>
              whatsapp
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Messenger />
            <Typography sx={{ fontSize: "12px", fontWeight: "500", mt: 1 }}>
              Facebook
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <InstagramSvg />
            <Typography sx={{ fontSize: "12px", fontWeight: "500", mt: 1 }}>
              Instagram
            </Typography>
          </Box> */}
            </Stack>
          </Box>
          {/* <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={300}
    /> */}
        </CardContent>
      </Card>
    </>
    // <Card sx={{ borderRadius: "15px" }}>
    //   <CardContent>
    //     <Stack
    //       direction={"row"}
    //       justifyContent="space-between"
    //       alignItems={"center"}
    //     >
    //       <Typography>Analytics</Typography>
    //       {/* <Button startIcon={<ArrowBackIos />} endIcon={<ArrowForwardIos />}>
    //                     Total
    //                 </Button> */}
    //     </Stack>
    //     <ReactApexChart
    //       options={options}
    //       series={series}
    //       type="bar"
    //       height={350}
    //     />
    //   </CardContent>
    // </Card>
  );
};

const CustomerRegisteredGraph = ({ users }) => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (!isArrayWithValues(users)) return;
    let seriesObj = {};
    let series = [];
    for (let user of users) {
      let { plans } = user;
      if (!isObjWithValues(plans)) continue;
      let allLedgers = [];
      Object.values(plans).map((i) => {
        if (isArrayWithValues(i.ledger))
          allLedgers = allLedgers.concat(i.ledger);
      });
      let sortedLedger = allLedgers.sort(
        (a, b) =>
          new Date(a.date_paid).getTime() - new Date(b.date_paid).getTime()
      );
      if (sortedLedger?.[0]?.date_paid) {
        let paidDate = formatDate(
          new Date(sortedLedger?.[0]?.date_paid),
          "mm-dd-yyyy"
        );
        if (!seriesObj[paidDate]) seriesObj[paidDate] = 0;
        seriesObj[paidDate] += 1;
      }
    }
    for (let date in seriesObj) {
      series.push([new Date(date).getTime(), seriesObj[date]]);
    }

    series = series.sort(
      (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()
    );
    setSeries(series);
  }, [users]);

  return (
    <>
      <Card sx={{ borderRadius: "15px", mt: 4, height: "100%" }}>
        <CardContent>
          <SectionLabel>Customers Registration</SectionLabel>
          <ReactApexChart
            options={customerRegisterOptions}
            series={[
              {
                data: series,
              },
            ]}
            type="area"
            height={350}
            // height={"100%"}
          />
        </CardContent>
      </Card>
    </>
    // <Card sx={{ borderRadius: "15px" }}>
    //   <CardContent>
    //     <Stack
    //       direction={"row"}
    //       justifyContent="space-between"
    //       alignItems={"center"}
    //     >
    //       <Typography>Analytics</Typography>
    //       {/* <Button startIcon={<ArrowBackIos />} endIcon={<ArrowForwardIos />}>
    //                     Total
    //                 </ButtonButton> */}
    //     </Stack>
    //     <ReactApexChart
    //       options={options}
    //       series={series}
    //       type="bar"
    //       height={350}
    //     />
    //   </CardContent>
    // </Card>
  );
};

// const CustomerLocationGraph = ({ customers }) => {
//   return (
//     <Card sx={{ borderRadius: "15px", mt: 4 }}>
//       <CardContent>
//         <SectionLabel>Customers Location</SectionLabel>
//         {isArrayWithValues(topUsersPlans) &&
//           topUsersPlans
//             .filter((i) => i.totalUsers)
//             .map((plan, index) => {
//               let { totalUsers, name } = plan;
//               return (
//                 <Box
//                   sx={{
//                     height: "45px",
//                     width: `${(validateNumber(totalUsers) / maxTotal) * 100}%`,
//                     borderRadius: "10px",
//                     //   backgroundColor: "#25D366",
//                     backgroundColor: colors[index],
//                     alignItems: "center",
//                     display: "flex",
//                     justifyContent: "center",
//                     margin: "18px 0px",
//                   }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: "15px",
//                       textAlign: "center",
//                       verticalAlign: "center",
//                       fontWeight: "600",
//                       color: "#fff",
//                       margin: "auto 0px",
//                     }}
//                   >
//                     {(validateNumber(totalUsers) / maxTotal) * 100 > 30
//                       ? `${name}: ${totalUsers}`
//                       : (validateNumber(totalUsers) / maxTotal) * 100 > 30
//                       ? totalUsers
//                       : ""}
//                   </Typography>
//                 </Box>
//               );
//             })}

//         <Divider sx={{ my: 2 }} />
//         <Stack
//           direction="row"
//           alignItems={"center"}
//           justifyContent="space-around"
//           sx={{ mt: 5 }}
//         >
//           {isArrayWithValues(topUsersPlans) &&
//             topUsersPlans
//               .filter((i) => i.totalUsers)
//               .map((plan, index) => {
//                 let { totalUsers, name } = plan;
//                 return (
//                   <Tooltip title={name}>
//                     <Box sx={{ textAlign: "center" }}>
//                       <Box
//                         sx={{
//                           height: "24px",
//                           width: "24px",
//                           borderRadius: "50%",
//                           backgroundColor: colors[index],
//                           //   backgroundColor: "#e8e8e8",
//                           margin: "0px auto",
//                         }}
//                       />
//                       <Typography
//                         sx={{ fontSize: "12px", fontWeight: "500", mt: 2 }}
//                       >
//                         {name && name.length > 10
//                           ? name.substring(0, 10) + "..."
//                           : name}
//                       </Typography>
//                     </Box>
//                   </Tooltip>
//                 );
//               })}
//         </Stack>
//       </CardContent>
//     </Card>
//   );
// };

const _filterLabels = [
  { label: "Today", value: "today" },
  { label: "This Week", value: "this_week" },
  { label: "This Month", value: "this_month" },
  { label: "This Year", value: "this_year" },
  { label: "All", value: "all" },
  { label: "Custom", value: "custom" },
];

export const CustomDateRangeFilter = ({
  dateRange,
  setDateRange,
  hideCustom,
  startDateLabel = "startDate",
  endDateLabel = "endDate",
  mobileMode = false,
  selectProps = {},
  formControlProps = {},
  containerSx = {},
  horizontal = true,
  allowed_filters = [],
  selectedValue,
  onSelectedValueChange,
  setByDefault = "all",
  show_clear_icon,
  onClearPress,
  custom_only,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCustom, setShowCustom] = useState(false);
  const [filterLabels, setFilterLabels] = useState(_filterLabels);
  const [_dateRange, _setDateRange] = useState({
    [startDateLabel]: null,
    [endDateLabel]: null,
  });
  const [selected, setSelected] = useState("");

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md")) || mobileMode;

  const customDateClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const customDateClose = () => {
    setAnchorEl(null);
  };

  // Avoid infinite loop by checking if selectedValue is defined and differs from the current selected value
  useEffect(() => {
    if (selectedValue !== undefined && selectedValue !== selected) {
      setSelected(selectedValue);
      if (selectedValue === "custom") {
        setShowCustom(true);
      } else {
        setShowCustom(false);
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    if (custom_only) {
      setSelected("custom");
      setShowCustom(true);
    }
  }, [custom_only]);

  // Avoid updating selected if onSelectedValueChange is not defined
  useEffect(() => {
    if (!selectedValue) {
      onSelectedValueChange && onSelectedValueChange(selected);
    }
  }, [selected]);

  useEffect(() => {
    console.log("RUNNING SETFiLTERs USEEFFECT");
    let array = [..._filterLabels].filter((i) =>
      hideCustom ? i.value !== "custom" : true
    );

    if (isArrayWithValues(allowed_filters)) {
      array = array?.filter((i) => allowed_filters?.includes(i?.value));
    }
    if (!selected && setByDefault)
      setSelected(setByDefault || array?.[0]?.value || "all");
    if (!array?.length !== filterLabels?.length) setFilterLabels(array);
  }, []);
  // }, [hideCustom, allowed_filters, setByDefault, selected]);

  const customDateOpen = Boolean(anchorEl);
  const id = customDateOpen ? "simple-popover" : undefined;

  const onDateSubmit = () => {
    customDateClose();
    let endDate = new Date(_dateRange?.[endDateLabel]);
    endDate.setDate(endDate?.getDate() + 1);
    setDateRange({ ..._dateRange, [endDateLabel]: endDate });
  };

  const onDefinedDateSubmit = (value) => {
    let endDate = new Date();
    let startDate;
    let currentDate = new Date();

    // Resetting endDate to the end of the current period
    endDate.setHours(23, 59, 59, 999);

    switch (value) {
      case "today":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        break;
      case "this_week":
        let firstDayOfWeek = currentDate.getDate() - currentDate.getDay();
        startDate = new Date(currentDate.setDate(firstDayOfWeek));
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 6);
        endDate.setHours(23, 59, 59, 999);
        break;
      case "this_month":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );
        endDate.setHours(23, 59, 59, 999);
        break;
      case "this_year":
        startDate = new Date(currentDate.getFullYear(), 0, 1);
        endDate = new Date(currentDate.getFullYear(), 11, 31);
        endDate.setHours(23, 59, 59, 999);
        break;
      case "all":
        startDate = null;
        endDate = null;
        break;
    }

    setDateRange({ [startDateLabel]: startDate, [endDateLabel]: endDate });
  };

  const handleSelectChange = (value) => {
    if (!selectedValue) {
      setSelected(value);
      if (value === "custom") {
        setShowCustom(true);
      } else {
        setShowCustom(false);
        onDefinedDateSubmit(value);
      }
    }
  };

  return (
    <>
      <Stack
        direction={horizontal ? "row" : "column"}
        justifyContent="flex-end"
        alignItems={"center"}
        spacing={2}
        sx={{ py: 2, ...containerSx }}
      >
        {!Boolean(custom_only) && (
          <>
            {isIpad ? (
              <FormControl
                fullWidth={!horizontal}
                size="small"
                {...formControlProps}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selected}
                  endAdornment={
                    <IconButton
                      sx={{
                        display: show_clear_icon ? "block" : "none",
                        mr: 1.8,
                      }}
                      onClick={() => onClearPress && onClearPress()}
                      size={"small"}
                    >
                      <Clear style={{ height: "18px", width: "18px" }} />
                    </IconButton>
                  }
                  fullWidth
                  {...selectProps}
                >
                  {filterLabels.map((filter) => (
                    <MenuItem
                      key={filter.value}
                      value={filter.value}
                      onClick={() => handleSelectChange(filter.value)}
                    >
                      {filter.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Stack
                direction="row"
                alignItems={"center"}
                spacing={2}
                sx={{
                  display: showCustom ? "none" : "block",
                  overflow: "hidden",
                  transition: "all 0.3s",
                }}
              >
                {filterLabels.map((filter) => (
                  <Button
                    key={filter.value}
                    sx={{
                      color:
                        selected === filter.value
                          ? "primary"
                          : "text.secondary",
                    }}
                    onClick={() => handleSelectChange(filter.value)}
                  >
                    {filter.label}
                  </Button>
                ))}
              </Stack>
            )}
          </>
        )}
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems={"center"}
          sx={{
            maxWidth: !showCustom ? "0%" : "100%",
            overflow: "hidden",
            transition: "all 0.3s",
          }}
          component={!horizontal ? Collapse : Box}
          in={showCustom}
        >
          {!isIpad && (
            <Button
              color="secondary"
              sx={{ whiteSpace: "nowrap" }}
              onClick={() => setShowCustom(false)}
            >
              {"Custom Date"}
            </Button>
          )}
          <Box onClick={customDateClick} sx={{ cursor: "pointer" }}>
            <TextField
              size="small"
              placeholder="Custom date"
              value={
                dateRange[endDateLabel] || dateRange[startDateLabel]
                  ? formatDate(
                      new Date(
                        new Date(dateRange[endDateLabel]).setDate(
                          new Date(dateRange[endDateLabel]).getDate() - 1
                        )
                      ),
                      "yyyy-mm-dd"
                    ) ==
                    formatDate(
                      new Date(dateRange[startDateLabel]),
                      "yyyy-mm-dd"
                    )
                    ? `${formatDate(
                        new Date(dateRange[startDateLabel]),
                        "relative_date"
                      )}`
                    : `${formatDate(
                        new Date(dateRange[startDateLabel]),
                        "relative_date"
                      )} - ${formatDate(
                        new Date(
                          new Date(dateRange[endDateLabel]).setDate(
                            new Date(dateRange[endDateLabel]).getDate() - 1
                          )
                        ),
                        "relative_date"
                      )}`
                  : ""
              }
              sx={{
                cursor: "pointer",
                pointerEvents: "none",
                minWidth:
                  formatDate(
                    new Date(dateRange[startDateLabel]),
                    "yyyy-mm-dd"
                  ) ===
                  formatDate(
                    new Date(
                      new Date(dateRange[endDateLabel]).setDate(
                        new Date(dateRange[endDateLabel]).getDate() - 1
                      )
                    ),
                    "yyyy-mm-dd"
                  )
                    ? "200px"
                    : "270px",
                transition: "all 0.3s",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarToday />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>
      </Stack>
      <Popover
        id={id}
        open={customDateOpen}
        anchorEl={anchorEl}
        onClose={customDateClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DateRange
          rangeColors={[theme?.palette?.primary?.main]}
          editableDateInputs={true}
          onChange={({ selection }) => {
            _setDateRange((state) => ({
              [endDateLabel]: selection ? selection.endDate : new Date(),
              [startDateLabel]: selection ? selection.startDate : new Date(),
            }));
          }}
          moveRangeOnFirstSelection={false}
          ranges={[
            {
              startDate: _dateRange[startDateLabel],
              endDate: _dateRange[endDateLabel],
              key: "selection",
            },
          ]}
        />
        <Stack direction="row" sx={{ mt: 2, px: 2, py: 2 }}>
          <Button sx={{ flex: 1 }} size="small" onClick={customDateClose}>
            Close
          </Button>
          <Button
            sx={{ flex: 1 }}
            variant="contained"
            size="small"
            onClick={onDateSubmit}
          >
            Submit
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

// export const CustomDateRangeFilter = ({
//   dateRange,
//   setDateRange,
//   hideCustom,
//   startDateLabel = "startDate",
//   endDateLabel = "endDate",
//   mobileMode = false,
//   selectProps = {},
//   formControlProps = {},
//   containerSx = {},
//   horizontal = true,
//   allowed_filters = [],
//   selectedValue,
//   onSelectedValueChange,
//   setByDefault = "all",
//   show_clear_icon,
//   onClearPress,
// }) => {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [showCustom, setShowCustom] = useState(false);
//   const [filterLabels, setFilterLabels] = useState(_filterLabels);
//   const [_dateRange, _setDateRange] = useState({
//     [startDateLabel]: null,
//     [endDateLabel]: null,
//   });
//   const [selected, setSelected] = useState("");

//   const theme = useTheme();
//   const isIpad = useMediaQuery(theme.breakpoints.down("md")) || mobileMode;

//   const customDateClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const customDateClose = () => {
//     setAnchorEl(null);
//   };

//   useEffect(() => {
//     if (selected !== selectedValue) {
//       setSelected(selectedValue);
//       if (selectedValue === "custom") setShowCustom("custom");
//     }
//   }, []);

//   useEffect(() => {
//     onSelectedValueChange && onSelectedValueChange(selected);
//   }, [selected]);

//   useEffect(() => {
//     let array = [..._filterLabels].filter((i) =>
//       hideCustom ? i.value !== "custom" : true
//     );

//     if (isArrayWithValues(allowed_filters)) {
//       array = array?.filter((i) => allowed_filters?.includes(i?.value));
//     }
//     if (!selected && setByDefault)
//       setSelected(setByDefault || array?.[0]?.value || "all");
//     setFilterLabels(array);
//   }, [hideCustom]);

//   const customDateOpen = Boolean(anchorEl);
//   const id = customDateOpen ? "simple-popover" : undefined;

//   const onDateSubmit = () => {
//     customDateClose();

//     let endDate = new Date(_dateRange?.[endDateLabel]);
//     endDate.setDate(endDate?.getDate() + 1);
//     setDateRange({ ..._dateRange, [endDateLabel]: endDate });
//   };

//   const onDefinedDateSubmit = (value) => {
//     let endDate = new Date();
//     let startDate;
//     let currentDate = new Date();

//     // Resetting endDate to the end of the current period
//     endDate.setHours(23, 59, 59, 999);

//     switch (value) {
//       case "today":
//         startDate = new Date(
//           currentDate.getFullYear(),
//           currentDate.getMonth(),
//           currentDate.getDate()
//         );
//         break;
//       case "this_week":
//         let firstDayOfWeek = currentDate.getDate() - currentDate.getDay();
//         startDate = new Date(currentDate.setDate(firstDayOfWeek));
//         startDate.setHours(0, 0, 0, 0);
//         endDate = new Date(startDate);
//         endDate.setDate(endDate.getDate() + 6);
//         endDate.setHours(23, 59, 59, 999);
//         break;
//       case "this_month":
//         startDate = new Date(
//           currentDate.getFullYear(),
//           currentDate.getMonth(),
//           1
//         );
//         endDate = new Date(
//           currentDate.getFullYear(),
//           currentDate.getMonth() + 1,
//           0
//         );
//         endDate.setHours(23, 59, 59, 999);
//         break;
//       case "this_year":
//         startDate = new Date(currentDate.getFullYear(), 0, 1);
//         endDate = new Date(currentDate.getFullYear(), 11, 31);
//         endDate.setHours(23, 59, 59, 999);
//         break;
//       case "all":
//         startDate = null;
//         endDate = null;
//         break;
//     }

//     setDateRange({ [startDateLabel]: startDate, [endDateLabel]: endDate });
//   };

//   // const onDefinedDateSubmit = (value) => {
//   //   let endDate = new Date();
//   //   let startDate;
//   //   let currentDate = new Date();
//   //   switch (value) {
//   //     case "today":
//   //       startDate = new Date(
//   //         currentDate.getFullYear(),
//   //         currentDate.getMonth(),
//   //         currentDate.getDate()
//   //       );
//   //       break;
//   //     case "this_week":
//   //       let first = currentDate.getDate() - currentDate.getDay();
//   //       startDate = new Date(currentDate.setDate(first));
//   //       break;
//   //     case "this_month":
//   //       startDate = new Date(
//   //         currentDate.getFullYear(),
//   //         currentDate.getMonth(),
//   //         1
//   //       );
//   //       break;
//   //     case "this_year":
//   //       startDate = new Date(currentDate.getFullYear(), 0, 1);
//   //       break;
//   //     case "all":
//   //       startDate = null;
//   //       endDate = null;
//   //       break;
//   //   }
//   //   setDateRange({ [startDateLabel]: startDate, [endDateLabel]: endDate });
//   // };
//   return (
//     <>
//       <Stack
//         direction={horizontal ? "row" : "column"}
//         justifyContent="flex-end"
//         alignItems={"center"}
//         spacing={2}
//         sx={{ py: 2, ...containerSx }}
//       >
//         {isIpad ? (
//           <FormControl
//             fullWidth={!horizontal}
//             size="small"
//             {...formControlProps}
//           >
//             {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
//             <Select
//               labelId="demo-simple-select-label"
//               id="demo-simple-select"
//               value={selected}
//               // label="Age"
//               // onChange={(e) => {
//               //   setSelected(e.target.value);
//               //   e.target.value === "custom"
//               //     ? setShowCustom(true)
//               //     : onDefinedDateSubmit(filter.value);
//               // }}
//               endAdornment={
//                 <IconButton
//                   sx={{
//                     display: show_clear_icon ? "block" : "none",
//                     mr: 1.8,
//                   }}
//                   onClick={() => onClearPress && onClearPress()}
//                   size={"small"}
//                 >
//                   <Clear style={{ height: "18px", width: "18px" }} />
//                 </IconButton>
//               }
//               fullWidth
//               {...selectProps}
//             >
//               {filterLabels.map((filter) => {
//                 return (
//                   <MenuItem
//                     value={filter.value}
//                     onClick={(e) => {
//                       setSelected(filter.value);
//                       if (filter.value === "custom") {
//                         setShowCustom(true);
//                       } else {
//                         setShowCustom(false);
//                         onDefinedDateSubmit(filter.value);
//                       }
//                     }}
//                   >
//                     {filter.label}
//                   </MenuItem>
//                 );
//               })}
//             </Select>
//           </FormControl>
//         ) : (
//           <Stack
//             direction="row"
//             // justifyContent="space-between"
//             alignItems={"center"}
//             spacing={2}
//             sx={{
//               display: showCustom ? "none" : "block",
//               // maxWidth: showCustom ? "0%" : "100%",
//               overflow: "hidden",
//               transition: "all 0.3s",
//             }}
//           >
//             {filterLabels.map((filter) => {
//               return (
//                 <Button
//                   // variant="contained"
//                   key={filter.value}
//                   sx={{
//                     color:
//                       selected === filter.value ? "primary" : "text.secondary",
//                   }}
//                   onClick={() => {
//                     setSelected(filter.value);
//                     filter.value === "custom"
//                       ? setShowCustom(true)
//                       : onDefinedDateSubmit(filter.value);
//                   }}
//                 >
//                   {filter.label}
//                 </Button>
//               );
//             })}
//           </Stack>
//         )}
//         {/* <Button color="secondary">{"Custom"}</Button> */}
//         <Stack
//           direction="row"
//           justifyContent="flex-end"
//           alignItems={"center"}
//           sx={{
//             maxWidth: !showCustom ? "0%" : "100%",
//             overflow: "hidden",
//             transition: "all 0.3s",
//           }}
//           component={!horizontal ? Collapse : Box}
//           in={showCustom}
//         >
//           {!isIpad && (
//             <Button
//               color="secondary"
//               sx={{ whiteSpace: "nowrap" }}
//               onClick={() => setShowCustom(false)}
//             >
//               {"Custom Date"}
//             </Button>
//           )}
//           <Box onClick={customDateClick} sx={{ cursor: "pointer" }}>
//             <TextField
//               size="small"
//               // label="Date"
//               placeholder="Custom date"
//               value={
//                 dateRange[endDateLabel] || dateRange[startDateLabel]
//                   ? formatDate(
//                       new Date(
//                         new Date(dateRange[endDateLabel]).setDate(
//                           new Date(dateRange[endDateLabel]).getDate() - 1
//                         )
//                       ),
//                       "yyyy-mm-dd"
//                     ) ==
//                     formatDate(
//                       new Date(dateRange[startDateLabel]),
//                       "yyyy-mm-dd"
//                     )
//                     ? `${formatDate(
//                         new Date(dateRange[startDateLabel]),
//                         "relative_date"
//                       )}`
//                     : `${formatDate(
//                         new Date(dateRange[startDateLabel]),
//                         "relative_date"
//                       )} - ${formatDate(
//                         new Date(
//                           new Date(dateRange[endDateLabel]).setDate(
//                             new Date(dateRange[endDateLabel]).getDate() - 1
//                           )
//                         ),
//                         "relative_date"
//                       )}`
//                   : ""
//               }
//               sx={{
//                 cursor: "pointer",
//                 pointerEvents: "none",
//                 minWidth:
//                   formatDate(
//                     new Date(dateRange[startDateLabel]),
//                     "yyyy-mm-dd"
//                   ) ===
//                   formatDate(
//                     new Date(
//                       new Date(dateRange[endDateLabel]).setDate(
//                         new Date(dateRange[endDateLabel]).getDate() - 1
//                       )
//                     ),
//                     "yyyy-mm-dd"
//                   )
//                     ? "200px"
//                     : "270px",
//                 transition: "all 0.3s",
//               }}
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <CalendarToday />
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           </Box>
//         </Stack>
//       </Stack>
//       <Popover
//         id={id}
//         open={customDateOpen}
//         anchorEl={anchorEl}
//         onClose={customDateClose}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "left",
//         }}
//       >
//         <DateRange
//           rangeColors={[theme?.palette?.primary?.main]}
//           editableDateInputs={true}
//           onChange={({ selection }) => {
//             console.log(selection, "<<<<selection");
//             _setDateRange((state) => ({
//               // [endDateLabel]: selection ? selection.startDate : new Date(),
//               // [startDateLabel]: selection ? selection.endDate : new Date(),
//               [endDateLabel]: selection ? selection.endDate : new Date(),
//               [startDateLabel]: selection ? selection.startDate : new Date(),
//             }));
//           }}
//           moveRangeOnFirstSelection={false}
//           // ranges={dateRange}
//           ranges={[
//             {
//               // ..._dateRange,
//               startDate: _dateRange[startDateLabel],
//               endDate: _dateRange[endDateLabel],
//               key: "selection",
//             },
//           ]}
//         />
//         <Stack direction="row" sx={{ mt: 2, px: 2, py: 2 }}>
//           <Button sx={{ flex: 1 }} size="small" onClick={customDateClose}>
//             Close
//           </Button>
//           <Button
//             sx={{ flex: 1 }}
//             variant="contained"
//             size="small"
//             onClick={onDateSubmit}
//           >
//             Submit
//           </Button>
//         </Stack>
//       </Popover>
//     </>
//   );
// };
const CustomFilterTextField = ({ dateRange, setDateRange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showCustom, setShowCustom] = useState(false);
  const [_dateRange, _setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const customDateClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const customDateClose = () => {
    setAnchorEl(null);
  };

  const customDateOpen = Boolean(anchorEl);
  const id = customDateOpen ? "simple-popover" : undefined;

  const onDateSubmit = () => {
    customDateClose();

    let endDate = new Date(_dateRange?.endDate);
    endDate.setDate(endDate?.getDate() + 1);
    setDateRange({ ..._dateRange, endDate });
  };
  const onDefinedDateSubmit = (value) => {
    let endDate = new Date().getTime();
    let startDate;
    let currentDate = new Date();
    switch (value) {
      case "today":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        break;
      case "this_week":
        let first = currentDate.getDate() - currentDate.getDay();
        startDate = new Date(currentDate.setDate(first));
        break;
      case "this_month":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        break;
      case "this_year":
        startDate = new Date(currentDate.getFullYear(), 0, 1);
        break;
      case "all":
        startDate = null;
        endDate = null;
        break;
    }
    setDateRange({ startDate, endDate });
  };
  return (
    <>
      <Box onClick={customDateClick} sx={{ cursor: "pointer" }}>
        <TextField
          size="small"
          // label="Date"
          value={
            dateRange.endDate || dateRange.startDate
              ? formatDate(new Date(dateRange.endDate), "yyyy-mm-dd") ==
                formatDate(new Date(dateRange.startDate), "yyyy-mm-dd")
                ? `${formatDate(
                    new Date(dateRange.startDate),
                    "relative_date"
                  )}`
                : `${formatDate(
                    new Date(dateRange.startDate),
                    "relative_date"
                  )} - ${formatDate(
                    new Date(dateRange.endDate),
                    "relative_date"
                  )}`
              : ""
          }
          sx={{
            cursor: "pointer",
            pointerEvents: "none",
            minWidth:
              formatDate(new Date(dateRange.startDate), "yyyy-mm-dd") ===
              formatDate(new Date(dateRange.endDate), "yyyy-mm-dd")
                ? "200px"
                : "270px",
            transition: "all 0.3s",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarToday />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Popover
        id={id}
        open={customDateOpen}
        anchorEl={anchorEl}
        onClose={customDateClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DateRange
          editableDateInputs={true}
          onChange={({ selection }) => {
            _setDateRange((state) => ({
              endDate: selection ? selection.endDate : new Date(),
              startDate: selection ? selection.startDate : new Date(),
            }));
          }}
          moveRangeOnFirstSelection={false}
          ranges={[
            {
              ..._dateRange,
              key: "selection",
            },
          ]}
        />
        <Stack direction="row" sx={{ mt: 2, px: 2, py: 2 }}>
          <Button sx={{ flex: 1 }} size="small" onClick={customDateClose}>
            Close
          </Button>
          <Button
            sx={{ flex: 1 }}
            variant="contained"
            size="small"
            onClick={onDateSubmit}
          >
            Submit
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

const plansContributionsPieOptions = {
  chart: {
    width: 380,
    type: "pie",
  },
  colors: digigoldGlobalColors,
  // colors: ["##dc5718", "#179397", "#23994a", "#1570bf", "#eaf40c", "#f4c30c", ""],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

export const TotalCard = ({
  title,
  value,
  values = [],
  suffix,
  backgroundColor,
  onClick,
  breakup,
  graphData,
  graphColors,
}) => {
  return (
    <>
      <Card
        sx={{
          border: "0.5px solid #e5eef8",
          // height: "100%",
          backgroundColor: backgroundColor || "background.paper",
          cursor: "pointer",
        }}
        // onClick={() => onClick && onClick()}
      >
        <CardContent sx={{ "&:last-child": { paddingBottom: "20px" } }}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Typography variant="h5" fontSize="16px" fontWeight={"500"}>
              {title}
            </Typography>
            <Button
              // variant="text"
              variant="contained"
              size="small"
              color="primary"
              sx={{ minWidth: "44px", height: "23px", borderRadius: "9px" }}
              onClick={() => onClick && onClick()}
            >
              <ArrowForward style={{ height: "17px", width: "17px" }} />
            </Button>
          </Stack>
          <Stack sx={{ my: 2.5, py: 1 }}>
            <ReactApexChart
              options={{
                ...totalCardChartOptions,
                colors: graphColors || digigoldGlobalColors,
              }}
              series={[
                {
                  data: isArrayWithValues(graphData)
                    ? graphData
                    : [
                        0, 0, 0, 0, 0,
                        // getRandomInt(10),
                        // getRandomInt(10),
                        // getRandomInt(10),
                        // getRandomInt(10),
                        // getRandomInt(10),
                        // getRandomInt(10),
                        // getRandomInt(10),
                        // getRandomInt(10),
                      ],
                },
              ]}
              type="line"
              height={65}
              width={"100%"}
            />
          </Stack>
          <Stack
            mt={2}
            alignItems="flex-end"
            direction={"row"}
            // justifyContent="space-around"
            flexWrap="wrap"
            spacing={10}
          >
            {values.map((obj) => {
              return (
                <Box>
                  <Typography
                    sx={{ fontSize: "14px", color: "text.secondary" }}
                  >
                    {obj.title}
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    sx={{ mt: 1, ...(obj.valueSx || {}) }}
                  >
                    <Typography
                      ml={1}
                      variant="h5"
                      fontSize="20px"
                      fontWeight={"500"}
                      mr={1}
                    >
                      {obj.prefix || ""}
                    </Typography>
                    <Tooltip title={obj.tooltip || ""}>
                      <Typography
                        variant="h5"
                        fontSize="20px"
                        fontWeight={"500"}
                      >
                        {obj.value}
                      </Typography>
                    </Tooltip>
                    <Typography
                      ml={1}
                      variant="body2"
                      fontSize="16px"
                      // color="text.secondary"
                    >
                      {obj.suffix || ""}
                    </Typography>
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </CardContent>
      </Card>
      {/* {isObjWithValues(breakup) && (
        <Card sx={{}}>
          <CardContent>
            {Object.keys(breakup).map((key) => {
              return (
                <RenderLabelValue
                  value={breakup[key]}
                  label={formatServerValue(key)}
                />
              );
            })}
          </CardContent>
        </Card>
      )} */}
    </>
  );
};

export const exportAsExcel = ({
  data,
  fileName = "export",
  sheetName = "Sheet 1",
}) => {
  const ws = utils.json_to_sheet(data);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "SheetJS");
  XLSX.writeFile(wb, `${fileName}.xlsx`);
  // const ws = window.XLS.utils.json_to_sheet(data);
  // const wb = {
  //   Sheets: {
  //     data,
  //   },
  //   SheetNames: ["data"],
  // };

  // const eb = window.XLS.write(wb, { bookType: "xlsx", type: "array" });
  // const blob = new Blob([eb], { type: EXCEL_TYPE });
  // saveAs(blob, fileName + EXCEL_EXTENSION);
};

const getClosedPlansCSV = ({ closed_plans, customers }) => {
  if (
    !Array.isArray(customers) ||
    !closed_plans ||
    typeof closed_plans !== "object"
  )
    return [];

  return Object.keys(closed_plans)
    .map((key) => {
      // const [planId, customerId] = key.split("_");
      const split_ids = key.split("_");
      let planId =
        `${split_ids?.[0]}` + (split_ids?.length > 3 ? `_${split_ids[1]}` : "");
      let customerId = split_ids?.[split_ids?.length - 2];
      const closedPlanDetails = closed_plans[key];

      const customer = customers.find((cust) => cust.id === customerId);
      if (!customer) return null;

      const { email, first_name, last_name, whatsapp, billing, branch, plans } =
        customer;

      // Find the plan by checking if the plan ID starts with the given planId
      let customerPlanKey = Object.keys(plans).find((planKey) =>
        planKey.startsWith(planId)
      );
      customerPlanKey = Object.keys(plans).find((planKey) =>
        planKey.startsWith(planId?.split("_")?.[0])
      );
      if (!customerPlanKey) return null;

      const customerPlan = plans[customerPlanKey];

      const customerName = `${first_name || ""} ${last_name || ""}`.trim();
      const phone = `${billing?.phone || whatsapp || ""}`.trim();

      return {
        ID: customer.id,
        "Customer Name": customerName,
        Email: email || "",
        Phone: phone || "",
        "Plan ID": planId?.split("_")?.[0],
        "Plan Name": customerPlan.plan_name || "",
        "Close Request By": closedPlanDetails.close_request_by || "",
        "Close By": closedPlanDetails.close_by || "",
        "Final Paid": validateNumber(closedPlanDetails.final_paid || ""),
        "Additional Charges": validateNumber(
          closedPlanDetails.additional_charges || ""
        ),
        Tax: validateNumber(closedPlanDetails.tax || ""),
        Penalty: validateNumber(closedPlanDetails.penalty || ""),
        "Bank Charges": validateNumber(closedPlanDetails.bank_charges || ""),
        "Additional Benefit": validateNumber(
          closedPlanDetails.additional_benefit || ""
        ),
        Branch: branch || "",
        "Joining Date":
          formatDate(
            new Date(customerPlan.joining_date),
            "dd:mm:yyyy hh:mm am/pm"
          ) || "",
        "Closed On":
          formatDate(
            new Date(closedPlanDetails.time),
            "dd:mm:yyyy hh:mm am/pm"
          ) || "",
      };
    })
    .filter(Boolean); // Filter out any null entries
};

const getDigigoldLedgersCSV = ({ order, store_id, currency, plan_id }) => {
  if (!isObjWithValues(order)) return;
  let {
    id,
    email,
    first_name,
    last_name,
    joining_date,
    totalPaid,
    totalExpected,
    totalMissed,
    whatsapp,
    billing,
    totalPaidContribution,
    plans,
    plan_name,
    date_paid,
    total,
    manual,
    collected_by,
    branch_name,
    product,
    mode,
    order_id,
    ref,
    plan_payment_count,
    duration,
  } = order;
  let object = {
    ID: id,
    Email: email,
    "Customer Name": `${first_name || ""} ${last_name || ""}`.trim(),
    "Unique ID": order[`digigold_uid_${store_id}`]?.[product] || "",
    "Plan Name": plan_name || "",
    Date: formatDate(new Date(date_paid), "dd:mm:yyyy hh:mm am/pm") || "",
    "Order ID": order_id || "",
    // Amount: `${total}`.trim() || "",
    "Payment Ref": ref || "",
    Amount: validateNumber(total),
    Manual: manual ? "Yes" : "No",
    Phone: `${billing?.phone || whatsapp || ""}`.trim() || "",
    "Collected By": collected_by || "",
    Mode:
      formatServerValue(mode) === "cod"
        ? "Cash"
        : formatServerValue(mode) || "",
    "Branch Name": branch_name || "",
    "Payment Count": `${plan_payment_count || ""}/${duration}`,
    // "Total Expected":
    //   `${currencySymbols?.[currency] || ""} ${totalExpected}`.trim() || "",
    // "Total Paid":
    //   `${currencySymbols?.[currency] || ""} ${totalPaid}`.trim() || "",
    // "Total Paid (%)": `${totalPaidContribution}%`.trim() || "",
    // "Total Missed":
    //   `${currencySymbols?.[currency] || ""} ${totalMissed}`.trim() || "",
  };

  let updatedObj = {};
  let orderedKeys = [
    "ID",
    "Customer Name",
    "Unique ID",
    "Email",
    "Phone",
    "Plan Name",
    "Date",
    "Order ID",
    "Payment Ref",
    "Amount",
    "Manual",
    "Phone",
    "Collected By",
    "Mode",
    "Branch Name",
    "Payment Count",
  ];
  for (let key of orderedKeys) updatedObj[key] = object[key] || " ";

  return updatedObj;
};
const getDigigoldLedgersPDF = ({
  order,
  store_id,
  index,
  currency,
  plan_id,
}) => {
  if (!isObjWithValues(order)) return;
  let {
    id,
    email,
    first_name,
    last_name,
    joining_date,
    totalPaid,
    totalExpected,
    totalMissed,
    whatsapp,
    billing,
    totalPaidContribution,
    plans,
    plan_name,
    date_paid,
    total,
    manual,
    collected_by,
    branch_name,
    product,
    mode,
    order_id,
    ref,
  } = order;
  let phone = `${billing?.phone || whatsapp || ""}`.trim() || "";
  let _mode =
    formatServerValue(mode) === "cod" ? "Cash" : formatServerValue(mode) || "";
  let object = {
    ID: id,
    "SR No.": `${index + 1}`,
    "Email/Phone": {
      title: email || phone,
      subtitle: email ? phone : "",
    },
    "Customer Name": {
      title: `${first_name || ""} ${last_name || ""}`.trim(),
      subtitle: `${order[`digigold_uid_${store_id}`]?.[product] || ""}`,
    },
    // "Unique ID": order[`digigold_uid_${store_id}`]?.[product] || "",
    "Plan Name": plan_name || "",
    "Received on": {
      title: formatDateDynamic(new Date(date_paid), "DD/MM/YYYY") || "",
      subtitle: formatDateDynamic(new Date(date_paid), "hh:mm A") || "",
    },
    Amount: `${currencySymbols?.[currency] || ""} ${validateNumber(total)}`,
    "Order ID": order_id || "",
    Mode: { title: _mode, subtitle: `Ref id: ${order_id}` },
    // Amount: `${total}`.trim() || "",
    "Payment Ref": ref || "",
    Manual: manual ? "Yes" : "No",
    // Phone: `${billing?.phone || whatsapp || ""}`.trim() || "",
    "Collected By": collected_by || "",
    "Branch Name": {
      title: branch_name || "",
      subtitle: collected_by ? `collected by: ${collected_by || ""}` : "",
    },
    // "Total Expected":
    //   `${currencySymbols?.[currency] || ""} ${totalExpected}`.trim() || "",
    // "Total Paid":
    //   `${currencySymbols?.[currency] || ""} ${totalPaid}`.trim() || "",
    // "Total Paid (%)": `${totalPaidContribution}%`.trim() || "",
    // "Total Missed":
    //   `${currencySymbols?.[currency] || ""} ${totalMissed}`.trim() || "",
  };

  let updatedObj = {};
  let orderedKeys = [
    "SR No.",
    "ID",
    "Customer Name",
    // "Unique ID",
    "Branch Name",
    "Plan Name",
    "Email/Phone",
    // "Phone",
    "Received on",
    // "Order ID",
    // "Payment Ref",
    "Amount",
    "Mode",
    // "Manual",
    // "Phone",
    // "Collected By",
  ];
  for (let key of orderedKeys) updatedObj[key] = object[key] || " ";

  return updatedObj;
};

const cus = ({ customer, store_id, currency, plan_id }) => {
  if (!isObjWithValues(customer)) return;
  let {
    id,
    email,
    first_name,
    last_name,
    joining_date,
    totalPaid,
    totalExpected,
    totalMissed,
    whatsapp,
    billing,
    totalPaidContribution,
    plans,
    branch,
    paidByPlans,
    plansActive,
    plan_status,
  } = customer;
  let firstPayment = getFirstPayment(plans);

  let object = {
    ID: id,
    Email: email,
    "Customer Name": `${first_name || ""} ${last_name || ""}`.trim(),
    "Total Expected": validateNumber(`${totalExpected}`.trim()),
    "Total Paid": validateNumber(`${totalPaid}`.trim()),
    "Total Paid (%)": `${totalPaidContribution}%`.trim() || "",
    "Total Missed": validateNumber(`${totalMissed}`.trim()),
    Phone: `${billing?.phone || whatsapp || ""}`.trim() || "",
    Branch: branch,
    "Refer By": `${customer?.digi_refer_obj?.first_name || ""} ${
      customer?.digi_refer_obj?.last_name || ""
    }`,
    "Enrolled By": firstPayment?.collector || "",
    // "Plans Active": plansActive ? "Active" : "Not Active",
    "Plan Status": allPlansStatusLabel?.[plan_status] || " ",
  };

  let joiningDate = getFirstPlanDate(plans);
  if (joiningDate)
    object["Joining Date"] =
      formatDate(new Date(joiningDate), "dd:mm:yyyy hh:mm am/pm") || "";

  let planAmounts = [];
  let planPayments = [];

  if (paidByPlans) {
    for (let planId in paidByPlans) {
      let {
        totalPaid,
        planName,
        totalNoPaymentsExpected,
        totalNoPaymentsPaid,
        totalExpected,
      } = paidByPlans?.[planId] || {};
      if (totalNoPaymentsPaid && totalNoPaymentsExpected) {
        // if(!object?.[`${planName} Amount`])
        object[
          `${planName} Payments`
        ] = `${totalNoPaymentsPaid}/${totalNoPaymentsExpected}`;
      }
      if (!planPayments?.includes(`${planName} Payments`))
        planPayments.push(`${planName} Payments`);
    }
  }
  if (paidByPlans) {
    for (let planId in paidByPlans) {
      let { totalPaid, planName } = paidByPlans?.[planId] || {};
      if (totalPaid && planName) {
        object[`${planName} Amount`] = totalPaid;
      }
      if (!planAmounts?.includes(`${planName} Amount`))
        planAmounts.push(`${planName} Amount`);
    }
  }

  if (plan_id) {
    if (customer?.[`digigold_uid_${store_id}`]?.[plan_id])
      object["Unique ID"] =
        customer[`digigold_uid_${store_id}`]?.[plan_id] || "";
    if (plans?.[plan_id]?.joining_date)
      object["Joining Date"] =
        formatDate(new Date(joining_date), "dd:mm:yyyy hh:mm am/pm") || "";
    if (plans?.[plan_id]?.plan_name)
      object["Plan Name"] = plans?.[plan_id]?.plan_name || "";
  } else {
    if (isObjWithValues(customer?.plans))
      object["Plans Enrolled"] =
        Object.values(customer?.plans)
          .map((i) => i.plan_name)
          .join(", ") || "";
  }
  let updatedObj = {};
  let orderedKeys = [];

  if (plan_id)
    orderedKeys = [
      "ID",
      "Customer Name",
      "Email",
      "Phone",
      "Refer By",
      "Enrolled By",
      "Branch",
      "Plan Name",
      "Unique ID",
      "Joining Date",
      "Plan Status",
      "Total Expected",
      "Total Paid",
      "Total Paid (%)",
      "Total Missed",
      ...planPayments,
      ...planAmounts,
    ];
  else {
    orderedKeys = [
      "ID",
      "Customer Name",
      "Email",
      "Phone",
      "Branch",
      "Refer By",
      "Enrolled By",
      joiningDate ? "Joining Date" : "",
      "Plan Status",
      "Plans Enrolled",
      "Total Expected",
      "Total Paid",
      "Total Paid (%)",
      "Total Missed",
      ...planPayments,
      ...planAmounts,
    ];
  }
  orderedKeys = orderedKeys.filter(Boolean);
  for (let key of orderedKeys) updatedObj[key] = object[key] || " ";

  return updatedObj;
};

const getDigigoldCustomerByPlansCSV = ({ customer, store_id, currency }) => {
  if (!isObjWithValues(customer)) return;
  let {
    id,
    email,
    first_name,
    last_name,
    joining_date,
    totalPaid,
    totalExpected,
    totalMissed,
    whatsapp,
    billing,
    totalPaidContribution,
    plans,
    branch,
    paidByPlans,
    plansActive,
    plan_status,
    whatsapp_id,
  } = customer;
  let firstPayment = getFirstPayment(plans);

  let rows = [];

  let planUid = customer?.[`digigold_uid_${store_id}`];
  // let plans = customer

  if (isObjWithValues(plans)) {
    for (let _plan_id in plans) {
      let plan = plans[_plan_id];
      let plan_id = plan?.product;
      let userPlanAnlytics = Object.values(paidByPlans)?.find(
        (i) => i?.plan_unique_id + "" === _plan_id + ""
      );

      let {
        totalPaid,
        planName,
        totalNoPaymentsExpected,
        totalNoPaymentsPaid,
        totalExpected,
        status,
        plan_unique_id,
      } = userPlanAnlytics || {};

      let object = {
        ID: id,
        Email: email,
        "Customer Name": `${first_name || ""} ${last_name || ""}`.trim(),
        Phone:
          `${whatsapp_id || billing?.phone || whatsapp || ""}`.trim() || "",
        Branch: branch,
        "Refer By": `${customer?.digi_refer_obj?.first_name || ""} ${
          customer?.digi_refer_obj?.last_name || ""
        }`,
        // "Enrolled By": firstPayment?.collector || "",
        // "Plans Active": plansActive ? "Active" : "Not Active",
        // "Plan Status": allPlansStatusLabel?.[plan_status] || " ",

        "Total Expected": validateNumber(`${totalExpected}`.trim()),
        "Total Paid": validateNumber(`${totalPaid}`.trim()),
        "Plan Status": formatServerValue(status),
        "Customer Plan ID": plan_unique_id,
        // "Total Paid (%)": `${totalPaidContribution}%`.trim() || "",
        // "Total Missed": validateNumber(`${totalMissed}`.trim()),
      };

      let joiningDate = getPlanJoiningDate(plan);
      if (joiningDate)
        object["Joining Date"] =
          formatDate(new Date(joiningDate), "dd:mm:yyyy hh:mm am/pm") || "";

      let planAmounts = [];
      let planPayments = [];

      if (totalNoPaymentsPaid && totalNoPaymentsExpected) {
        // if(!object?.[`${planName} Amount`])
        object[
          `Payments Made`
        ] = `${totalNoPaymentsPaid}/${totalNoPaymentsExpected}`;
      }

      if (customer?.[`digigold_uid_${store_id}`]?.[plan_id])
        object["Unique ID"] =
          customer[`digigold_uid_${store_id}`]?.[plan_unique_id] ||
          customer[`digigold_uid_${store_id}`]?.[plan_id] ||
          "";
      if (plans?.[plan_id]?.joining_date)
        object["Joining Date"] =
          formatDate(new Date(joining_date), "dd:mm:yyyy hh:mm am/pm") || "";
      if (plans?.[plan_id]?.plan_name)
        object["Plan Name"] = plans?.[plan_id]?.plan_name || "";
      if (!object["Plan Name"] && planName) object["Plan Name"] = planName;

      let updatedObj = {};
      let orderedKeys = [];

      if (plan_id)
        orderedKeys = [
          "ID",
          "Customer Name",
          "Email",
          "Phone",
          "Refer By",
          "Enrolled By",
          "Branch",
          "Plan Name",
          "Unique ID",
          "Customer Plan ID",
          "Joining Date",
          "Plan Status",
          "Total Expected",
          "Total Paid",
          "Payments Made",
          // "Total Paid (%)",
          // "Total Missed",
          // ...planPayments,
          // ...planAmounts,
        ];
      else {
        orderedKeys = [
          "ID",
          "Customer Name",
          "Email",
          "Phone",
          "Branch",
          "Refer By",
          "Enrolled By",
          joiningDate ? "Joining Date" : "",
          "Plan Status",
          // "Plans Enrolled",
          "Total Expected",
          "Total Paid",
          "Payments Made",
          // "Total Paid (%)",
          // "Total Missed",
          // ...planPayments,
          // ...planAmounts,
        ];
      }
      orderedKeys = orderedKeys.filter(Boolean);
      for (let key of orderedKeys) updatedObj[key] = object[key] || " ";

      rows.push(updatedObj);
    }
  }
  return rows;
};
const getDigigoldCustomerPDF = ({
  customer,
  store_id,
  currency,
  plan_id,
  index,
}) => {
  if (!isObjWithValues(customer)) return;
  let {
    id,
    email,
    first_name,
    last_name,
    joining_date,
    totalPaid,
    totalExpected,
    totalMissed,
    whatsapp,
    billing,
    totalPaidContribution,
    plans,
    branch,
    paidByPlans,
    plansActive,
    plan_status,
  } = customer;
  let firstPayment = getFirstPayment(plans);
  let phone = `${billing?.phone || whatsapp || ""}`.trim() || "";
  let object = {
    "SR No.": `${index + 1}`,
    "Email/Phone": { title: email, subtitle: phone },
    "Customer Name": {
      title: `${first_name || ""} ${last_name || ""}`.trim(),
      subtitle: `Cust. Id: ${id}`,
    },
    "Total Expected": `${currencySymbols[currency]} ${validateNumber(
      `${totalExpected}`.trim()
    )}`,
    "Total Paid": {
      title: `${currencySymbols[currency]} ${validateNumber(
        `${totalPaid}`.trim()
      )}`,
      subtitle: `In (%): ${totalPaidContribution || ""}%`.trim() || "",
    },
    "Total Paid (%)": `${totalPaidContribution || ""}%`.trim() || "",
    "Total Missed": `${currencySymbols[currency]} ${validateNumber(
      `${totalMissed}`.trim()
    )}`,
    // Phone: `${billing?.phone || whatsapp || ""}`.trim() || "",
    Branch: branch,
    "Refer By": `${customer?.digi_refer_obj?.first_name || ""} ${
      customer?.digi_refer_obj?.last_name || ""
    }`,
    "Enrolled By": firstPayment?.collector || "",
    // "Plans Active": plansActive ? "Active" : "Not Active",
    "Plan Status": allPlansStatusLabel?.[plan_status] || " ",
  };

  let joiningDate = getFirstPlanDate(plans);
  if (joiningDate)
    object["Joining Date"] =
      formatDate(new Date(joiningDate), "dd:mm:yyyy hh:mm am/pm") || "";

  let planAmounts = [];
  let planPayments = [];

  if (paidByPlans) {
    for (let planId in paidByPlans) {
      let {
        totalPaid,
        planName,
        totalNoPaymentsExpected,
        totalNoPaymentsPaid,
      } = paidByPlans?.[planId] || {};
      if (totalNoPaymentsPaid && totalNoPaymentsExpected) {
        // if(!object?.[`${planName} Amount`])
        object[
          `${planName} Payments`
        ] = `${totalNoPaymentsPaid}/${totalNoPaymentsExpected}`;
      }
      if (!planPayments?.includes(`${planName} Payments`))
        planPayments.push(`${planName} Payments`);
    }
  }
  if (paidByPlans) {
    for (let planId in paidByPlans) {
      let { totalPaid, planName } = paidByPlans?.[planId] || {};
      if (totalPaid && planName) {
        object[`${planName} Amount`] = totalPaid;
      }
      if (!planAmounts?.includes(`${planName} Amount`))
        planAmounts.push(`${planName} Amount`);
    }
  }

  if (plan_id) {
    if (customer?.[`digigold_uid_${store_id}`]?.[plan_id])
      object["Customer Name"].subtitle =
        customer[`digigold_uid_${store_id}`]?.[plan_id] || "";
    if (plans?.[plan_id]?.joining_date)
      object["Joining Date"] =
        formatDate(new Date(joining_date), "dd:mm:yyyy hh:mm am/pm") || "";
    if (plans?.[plan_id]?.plan_name)
      object["Plan Name"] = plans?.[plan_id]?.plan_name || "";
  } else {
    if (isObjWithValues(customer?.plans))
      object["Plans Enrolled"] =
        Object.values(customer?.plans)
          .map((i) => i.plan_name)
          .join(", ") || "";
  }
  let updatedObj = {};
  let orderedKeys = [];

  if (plan_id)
    orderedKeys = [
      "SR No.",
      // "ID",
      "Customer Name",
      "Email/Phone",
      // "Phone",
      // "Refer By",
      // "Enrolled By",
      // "Branch",
      "Plan Name",
      "Unique ID",
      "Joining Date",
      // "Plan Status",
      "Total Expected",
      "Total Paid",
      // "Total Paid (%)",
      "Total Missed",
      // ...planPayments,
      // ...planAmounts,
    ];
  else {
    orderedKeys = [
      "SR No.",
      // "ID",
      "Customer Name",
      "Email/Phone",
      // "Phone",
      // "Branch",
      // "Refer By",
      // "Enrolled By",
      joiningDate ? "Joining Date" : "",
      // "Plan Status",
      "Plans Enrolled",
      "Total Expected",
      "Total Paid",
      // "Total Paid (%)",
      "Total Missed",
      // ...planPayments,
      // ...planAmounts,
    ];
  }
  orderedKeys = orderedKeys.filter(Boolean);
  for (let key of orderedKeys) updatedObj[key] = object[key] || " ";

  return updatedObj;
};

const totalCardChartOptions = {
  chart: {
    type: "line",
    // width: 100,
    // height: 35,
    sparkline: {
      enabled: true,
    },
  },
  colors: digigoldGlobalColors,
  grid: {
    padding: {
      top: 5,
      bottom: 5,
    },
  },
  stroke: {
    show: true,
    curve: "smooth",
    lineCap: "butt",
    colors: undefined,
    width: 3,
    dashArray: 0,
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

const customerRegisterOptions = {
  chart: {
    id: "area-datetime",
    type: "area",
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
    toolbar: { show: false },
  },
  colors: digigoldGlobalColors,
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },
  xaxis: {
    type: "datetime",
    // min: new Date("01 Mar 2012").getTime(),
    tickAmount: 6,
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
};

export const SectionLabel = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
`;

const PlanCardColorBox = styled(Box)`
  border-radius: 8px;
  padding: 10px 8px;
  margin: 9px 0px;
`;
const PlanCardLabel = styled(Typography)`
  font-size: 13px;
  color: #808080;
`;
const PlanCardValue = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;
