import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Calls from "../../../customers/components/Calls";
import { useDispatch, useSelector } from "react-redux";
import {
  backendDoamin,
  Base64,
  isArrayWithValues,
  settingsEndpoint,
  validateNumber,
} from "../../../../helper";
import { EDIT_CHATS } from "../../../../redux/actions/chatActions";
import { LoadingButton } from "@mui/lab";
import { Sync } from "@mui/icons-material";
import { isObjWithValues } from "jwero-javascript-utils";

const Activities = () => {
  const [logsArray, setLogsArray] = useState([]);
  const [fetchingLogsArray, setFetchingLogsArray] = useState(false);
  const dispatch = useDispatch();
  const allCallHistory = useSelector((state) => state.chats.allCallHistory);
  const exotelIntegration = useSelector(
    (state) => state.settings.exotelIntegration
  );
  const getAllLogs = async () => {
    setFetchingLogsArray(true);
    let res = await settingsEndpoint({
      endpoint: "call_history_",
      params: {
        regex: true,
      },
      method: "GET",
    });

    if (isArrayWithValues(res))
      dispatch({
        type: EDIT_CHATS,
        payload: {
          allCallHistory: res
            ?.sort(
              (a, b) =>
                validateNumber(b.start_time) - validateNumber(a.start_time)
            )
            ?.map((i) => {
              if (
                i?.recording_url &&
                i?.recording_url?.includes("recordings.exotel.com")
              ) {
                i.recording_url = `${backendDoamin}/stream-audio?recording_url=${
                  i?.recording_url
                }&authorization=${
                  "Basic " +
                  Base64.btoa(
                    `${exotelIntegration.api_key}:${exotelIntegration?.api_token}`
                  )
                }`;
                // i.recording_url = i.recording_url?.replace(
                //   "https://",
                //   `https://${exotelIntegration.api_key}:${exotelIntegration?.api_token}@`
                // );
              }
              return i;
            }),
        },
      });
    setFetchingLogsArray(false);
  };
  useEffect(async () => {
    if (
      !isArrayWithValues(allCallHistory) &&
      isObjWithValues(exotelIntegration)
    ) {
      getAllLogs();
    }
    return () => {};
  }, [exotelIntegration]);
  useEffect(() => {
    if (isArrayWithValues(allCallHistory)) setLogsArray(allCallHistory);

    return () => {};
  }, [allCallHistory]);

  return (
    <Box
      sx={{
        px: 2,
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ px: 4 }}
      >
        <Typography variant="h6">Call History</Typography>
        <IconButton
          onClick={() => {
            getAllLogs();
          }}
          disabled={fetchingLogsArray}
        >
          <Sync />
        </IconButton>
        {/* <LoadingButton variant='outlined' onClick={() => {
                    getAllLogs()
                }}>
                    Refresh
                </LoadingButton> */}
      </Stack>

      <Calls
        logsArray={allCallHistory || []}
        fetchingLogsArray={fetchingLogsArray}
      />
    </Box>
  );
};

export default Activities;
